import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled/macro';
import { keyframes } from '@emotion/react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {
    IconButton,
    useTheme,
    Theme
} from '@mui/material';

const pointUp = keyframes`
    from, 20%, 53%, 80%, to {
        transform: translate3d(0,0,0);
    }
    40%, 43% {
        transform: translate3d(0, -30px, 0);
    }
    70% {
        transform: translate3d(0, -15px, 0);
    }
    90% {
        transform: translate3d(0,-4px,0);
    }
`;

const GoTopBn = styled(IconButton)`
    position: fixed;
    bottom: 30px;
    right: 30px;
    background: ${({ theme }: { theme: Theme }) => theme.sidebar.background};
    width: 50px;
    height: 50px;
    opacity: 0.4;
    transition: all 0.6s ease;
    &:hover {
        background: ${({ theme }: { theme: Theme }) => theme.sidebar.background};
        box-shadow: 1px 1px 5px ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
        opacity: 1;
        & > svg {
            animation: ${pointUp} 1.5s ease infinite;
        }
    }
    & > svg {
        color: ${({ theme }: { theme: Theme }) => theme.palette.primary.contrastText};
    }
`;

const GoToTop = () => {

    const [isVisible, setIsVisible] = useState<boolean>(false);
    const theme = useTheme();

    useEffect(() => {

        const toggleVisibility = () => {
            const isScrolled = document.documentElement.scrollTop;
            setIsVisible(isScrolled > 200);
        };

        window.addEventListener("scroll", toggleVisibility);

        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo(
            {
                top: 0,
                behavior: "smooth"
            }
        );
    };

    return (
        <GoTopBn
            theme={theme}
            onClick={handleScrollToTop}
            sx={{
                visibility: isVisible ? "visible" : "hidden",
                opacity: isVisible ? "1" : "0 !important"
            }}
        >
            <ArrowUpwardIcon />
        </GoTopBn>
    );
};

export default GoToTop;