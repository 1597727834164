import AuthGuard from "../components/AuthGuard";
import {
    SIGN_IN_PATH,
    PW_FORGOT_PATH,
    REGISTER_PATH,
    RESET_PATH,
    PROFILE_PATH,
    HOME_PATH,
    SEARCH_PATH,
    GUIDELINE_PATH,
    ATC_PATH,
    PRODUCTS_PATH,
    APRESC_PATH,
    CALC_PATH,
    CIV_PATH,
    PINJ_PATH,
    NOTIFICATIONS_PATH,
    NOTIFY_CREATE_PATH,
    NOTIFY_EDIT_PATH,
    USERS_PATH,
    ENTITIES_PATH,
    USERS_CREATE_PATH,
    USERS_EDIT_PATH,
    ENTITIES_CREATE_PATH,
    ENTITIES_EDIT_PATH,
    SEARCH_DETAIL_PATH,
    SEARCH_ATC_PATH,
    DETAILS_ATC_PATH,
    SEARCH_PRODUCTS_PATH,
    DETAILS_PRODUCTS_PATH,
    CALC_FN_PATH,
    ADS_PATH,
    ADS_CREATE_PATH,
    ADS_EDIT_PATH,
    CIV_RESULTS_PATH,
    PINJ_DETAILS_PATH,
    AGENTS_PATH,
    DCI_INTERACTIONS,
} from "../constants/routes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SearchIcon from "../components/icons/SearchIcon";
import GuidelinesIcon from "../components/icons/GuidelinesIcon";
import AtcIcon from "../components/icons/AtcIcon";
import ProductIcon from "../components/icons/ProductIcon";
import AnalisePrescIcon from "../components/icons/AnalisePrescIcon";
import IVCompatibilityIcon from "../components/icons/IVCompatibility";
import CalcIcon from "../components/icons/CalcIcon";
import PInjIcon from "../components/icons/PInjIcon";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import AdminAuthGuard from "../components/AdminAuthGuard";
import { MainFeaturesCode } from "../constants/featureCodes";
import CampaignIcon from "@mui/icons-material/Campaign";
import { Translation } from "react-i18next";
import LabIcon from "../components/icons/LabIcon";
import {
    LazyATC,
    LazyAdminAdsCrd,
    LazyAdminAdsLst,
    LazyAdminAgentLst,
    LazyAdminEntCrd,
    LazyAdminEntLst,
    LazyAdminNotCrd,
    LazyAdminNotLst,
    LazyAdminUsersCrd,
    LazyAdminUsersLst,
    LazyCalculator,
    LazyClinicalChecker,
    LazyDashboard,
    LazyDciAlert,
    LazyDetails,
    LazyForgotPW,
    LazyGuidelines,
    LazyHealthCalculators,
    LazyIV,
    LazyIVResults,
    LazyPInj,
    LazyPInjResults,
    LazyProducts,
    LazyProfile,
    LazyRegister,
    LazyResetPW,
    LazySearch,
    LazySign,
} from "./LazyRoutes";

const authRoutes = {
    id: "Auth",
    path: "/auth",
    children: [
        {
            path: SIGN_IN_PATH,
            name: "Sign In",
            component: LazySign,
        },
        {
            path: PW_FORGOT_PATH,
            name: "Forgot Password",
            component: LazyForgotPW,
        },
        {
            path: RESET_PATH,
            name: "Password Reset",
            component: LazyResetPW,
        },
        {
            path: REGISTER_PATH,
            name: "Regist",
            component: LazyRegister,
        },
    ],
};

const profile = {
    id: "Profile",
    featureCodes: MainFeaturesCode.DashBoard,
    path: PROFILE_PATH,
    name: "Profile",
    containsHome: true,
    component: LazyProfile,
    guard: AuthGuard,
};

const details = {
    id: "Details",
    featureCodes: MainFeaturesCode.Search,
    path: SEARCH_DETAIL_PATH,
    name: "Details",
    containsHome: true,
    component: LazyDetails,
    guard: AuthGuard,
};

const dciAlerts = {
    id: "Alerts",
    featureCodes: MainFeaturesCode.Search,
    path: DCI_INTERACTIONS,
    name: "Alerts",
    containsHome: true,
    component: LazyDciAlert,
    guard: AuthGuard,
};

const dashboard = {
    id: MainFeaturesCode.DashBoard,
    featureCodes: MainFeaturesCode.DashBoard,
    path: HOME_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Your")}
                    <em>{t("Dashboard")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Dashboard",
    description: "Dashboard",
    icon: <DashboardIcon />,
    containsHome: true,
    component: LazyDashboard,
    guard: AuthGuard,
};

const searchFeature = {
    id: MainFeaturesCode.Search,
    featureCodes: MainFeaturesCode.Search,
    path: SEARCH_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Search")} <em>{t("Drugs & Health Products")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Search",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "General search engine for quick access to information you are looking for"
                    )}
                    .<br />
                    {t(
                        "Allows you to search by DCI, Product Name or Product of Health, Laboratory, Hospital Code of Medicine (CHNM) or put National Code Product (CNP), Therapeutic Indication, Guidelines or Bridges Articles"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <SearchIcon />,
    containsHome: true,
    component: LazySearch,
    guard: AuthGuard,
};

const guidelinesFeature = {
    id: MainFeaturesCode.Guidelines,
    featureCodes: MainFeaturesCode.Guidelines,
    path: GUIDELINE_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Guidelines")}
                    <em>{t("Clinical Guidance Standards")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Guidelines",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "In this tab you can find Guidelines on the main pathologies and clinical areas.<br/>You can also consult the NOC (Clinical Guidance Standards) published by DGS"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <GuidelinesIcon />,
    containsHome: true,
    component: LazyGuidelines,
    guard: AuthGuard,
};

const atcFeature = {
    id: MainFeaturesCode.ATC,
    featureCodes: MainFeaturesCode.ATC,
    path: ATC_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("ATC")}
                    <em>{t("Classification of therapeutic substances")}</em>
                </>
            )}
        </Translation>
    ),
    header: "ATC Classification",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "The ATC (Anatomical Therapeutic Chemical) Classification developed by WHO allows intuitively search the Medicines, as they are grouped by group anatomical class, subsequently by its therapeutic class and finally chemically grouped. This classification allows you to have a comprehensive view of the market by the different classes"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <AtcIcon />,
    containsHome: true,
    component: LazyATC,
    guard: AuthGuard,
};

const produtcsFeature = {
    id: MainFeaturesCode.ProductsClassification,
    featureCodes: MainFeaturesCode.ProductsClassification,
    path: PRODUCTS_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Health Products")}
                    <em>{t("Classification")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Product Classification",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "Health Products correspond to other Pharmaceutical Specialties that do not Medicines, including:<br/>Food supplements, Medical devices, Medical Hygiene and personal care, Food, homeopathy, among others.<br/>In order to facilitate the research of this type of product, Simposium has created its own classification of easy consultation"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <ProductIcon />,
    containsHome: true,
    component: LazyProducts,
    guard: AuthGuard,
};

const atcDetails = {
    id: "atcDetails",
    featureCodes: MainFeaturesCode.ATC,
    path: DETAILS_ATC_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("ATC Drugs")}
                    <em>{t("Research Details")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazyDetails,
    guard: AuthGuard,
};

const atcSearch = {
    id: "atcSearch",
    featureCodes: MainFeaturesCode.ATC,
    path: SEARCH_ATC_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("ATC Drugs")}
                    <em>{t("Research")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazySearch,
    guard: AuthGuard,
};

const productsSearch = {
    id: "productsSearch",
    featureCodes: MainFeaturesCode.ProductsClassification,
    path: SEARCH_PRODUCTS_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Health Products")}
                    <em>{t("Research")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazySearch,
    guard: AuthGuard,
};

const productsDetails = {
    id: "productsDetails",
    featureCodes: MainFeaturesCode.ProductsClassification,
    path: DETAILS_PRODUCTS_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Drugs & Health Products")}
                    <em>{t("Details")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazyDetails,
    guard: AuthGuard,
};

const apFeature = {
    id: MainFeaturesCode.PrescriptionAnalyses,
    featureCodes: MainFeaturesCode.PrescriptionAnalyses,
    path: APRESC_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Clinical Checker")}
                    <em>{t("Prescription Analysis")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Clinical Checker",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "Reconciling the patient's pathophysiological profile and the prescribed medication, we provide a tool that allows you to assist in validating a prescription or dispensing medication"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <AnalisePrescIcon />,
    containsHome: true,
    component: LazyClinicalChecker,
    guard: AuthGuard,
};

const calcFeature = {
    id: MainFeaturesCode.HealthCalc,
    featureCodes: MainFeaturesCode.HealthCalc,
    path: CALC_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Calculators")}
                    <em>{t("for health purpose")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Health Calculator",
    description: "",
    icon: <CalcIcon />,
    containsHome: true,
    component: LazyHealthCalculators,
    guard: AuthGuard,
};

const IndividualCalcFN = {
    id: "individualCalc",
    featureCodes: MainFeaturesCode.HealthCalc,
    path: CALC_FN_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Individual")}
                    <em>{t("Calculator")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazyCalculator,
    guard: AuthGuard,
};

const ivFeature = {
    id: MainFeaturesCode.IVCompability,
    featureCodes: MainFeaturesCode.IVCompability,
    path: CIV_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Compatibilities")}
                    <em>{t("IV Analyse")}</em>
                </>
            )}
        </Translation>
    ),
    header: "IV Compatibilities",
    description: "",
    icon: <IVCompatibilityIcon />,
    containsHome: true,
    component: LazyIV,
    guard: AuthGuard,
};

const ivResults = {
    id: "icResults",
    featureCodes: MainFeaturesCode.IVCompability,
    path: CIV_RESULTS_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Compatibilities")}
                    <em>{t("IV Analyse Details")}</em>
                </>
            )}
        </Translation>
    ),
    containsHome: true,
    component: LazyIVResults,
    guard: AuthGuard,
};

const piFeature = {
    id: MainFeaturesCode.InjectionPreparation,
    featureCodes: MainFeaturesCode.InjectionPreparation,
    path: PINJ_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {t("Preparation")}
                    <em>{t("of Injectables")}</em>
                </>
            )}
        </Translation>
    ),
    header: "Injectable Preparation",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "This content provides information on the preparation and administration of injectable medicinal products. This content was developed into the Code (CHNM) and includes information such as: reconstitution, dilution, excipients of mandatory declaration, administration, among others"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <PInjIcon />,
    containsHome: true,
    component: LazyPInj,
    guard: AuthGuard,
};

const piDetails = {
    id: "InjectableDetails",
    featureCodes: MainFeaturesCode.InjectionPreparation,
    path: PINJ_DETAILS_PATH,
    name: (
        <Translation>
            {(t) => <>{t("Injectable Preparation Details")}</>}
        </Translation>
    ),
    containsHome: true,
    component: LazyPInjResults,
    guard: AuthGuard,
};

const notifications = {
    id: "Notifications",
    path: NOTIFICATIONS_PATH,
    header: "Notifications",
    icon: <NotificationsActiveIcon />,
    containsHome: true,
    component: LazyAdminNotLst,
    guard: AdminAuthGuard,
};

const createNotifications = {
    id: "Create Notifications",
    path: NOTIFY_CREATE_PATH,
    component: LazyAdminNotCrd,
    guard: AdminAuthGuard,
};

const editNotifications = {
    id: "Edit Notifications",
    path: NOTIFY_EDIT_PATH,
    component: LazyAdminNotCrd,
    guard: AdminAuthGuard,
};

const users = {
    id: "Users",
    path: USERS_PATH,
    header: "Users",
    icon: <PersonIcon />,
    containsHome: true,
    component: LazyAdminUsersLst,
    guard: AdminAuthGuard,
};

const createUser = {
    id: "Create User",
    path: USERS_CREATE_PATH,
    component: LazyAdminUsersCrd,
    guard: AdminAuthGuard,
};

const editeUser = {
    id: "Edit User",
    path: USERS_EDIT_PATH,
    component: LazyAdminUsersCrd,
    guard: AdminAuthGuard,
};

const entities = {
    id: "Entities",
    path: ENTITIES_PATH,
    header: "Entities",
    icon: <BusinessIcon />,
    containsHome: true,
    component: LazyAdminEntLst,
    guard: AdminAuthGuard,
};

const createEntity = {
    id: "Create User",
    path: ENTITIES_CREATE_PATH,
    component: LazyAdminEntCrd,
    guard: AdminAuthGuard,
};

const editeEntity = {
    id: "Edit User",
    path: ENTITIES_EDIT_PATH,
    component: LazyAdminEntCrd,
    guard: AdminAuthGuard,
};

const ads = {
    id: "Ads",
    path: ADS_PATH,
    header: "Ads",
    icon: <CampaignIcon />,
    containsHome: true,
    component: LazyAdminAdsLst,
    guard: AdminAuthGuard,
};

const createAdvertises = {
    id: "Create Advertising",
    path: ADS_CREATE_PATH,
    component: LazyAdminAdsCrd,
    guard: AdminAuthGuard,
};

const editeAdvertises = {
    id: "Edit Advertising",
    path: ADS_EDIT_PATH,
    component: LazyAdminAdsCrd,
    guard: AdminAuthGuard,
};

const agents = {
    id: "Agents",
    path: AGENTS_PATH,
    header: "Agents",
    icon: <LabIcon fill="true" />,
    containsHome: true,
    component: LazyAdminAgentLst,
    guard: AdminAuthGuard,
};

/*
const ebsco = {
    id: MainFeaturesCode.Ebsco,
    featureCodes: MainFeaturesCode.Ebsco,
    path: EBSCO_PATH,
    name: (
        <Translation>
            {(t) => (
                <>
                    {"Nutrition Reference Center"}
                    <em>{"EBSCO Health"}</em>
                </>
            )}
        </Translation>
    ),
    header: "Nutrition Reference Center",
    description: (
        <Translation>
            {(t) => (
                <>
                    {t(
                        "The most complete database of nutrition content based on scientific evidence"
                    )}
                    .<br />
                    {t(
                        "Exclusive access to a wide range of resources aimed at healthcare professionals and patients"
                    )}
                    .
                </>
            )}
        </Translation>
    ),
    icon: <CitricIcon />,
    containsHome: true,
    component: LazyNutritionCenter,
    guard: AuthGuard,
    isHighlighted: true,
};

const dynaMed = {
  id: MainFeaturesCode.DynaMed,
  featureCodes: MainFeaturesCode.DynaMed,
  path: DYNAMED_PATH,
  name: (
    <Translation>
      {(t) => (
        <>
          {"DynaMed"}
          <em>{"EBSCO Health"}</em>
        </>
      )}
    </Translation>
  ),
  header: "DynaMed",
  description: "Combina a evidência clínica mais recente com a orientação de especialistas líderes e uma experiência personalizada e fácil de usar para criar um recurso de apoio à decisão clínica de última geração.",
  icon: <PillsIcon fill />,
  containsHome: true,
  component: LazyDynamed,
  guard: AuthGuard,
  isHighlighted: true
};
*/

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes];
// Routes with sidebar layout
export const withSidebarLayoutRoutes = [
    dashboard,
    searchFeature,
    apFeature,
    ivFeature,
    piFeature,
    atcFeature,
    produtcsFeature,
    guidelinesFeature,
    calcFeature,
    // ebsco,
    // dynaMed
];
// Routes with sidebar layout, but not showed on menu
export const WSBLNotOnSidebarRoutes = [
    profile,
    details,
    dciAlerts,
    atcSearch,
    atcDetails,
    productsSearch,
    productsDetails,
    IndividualCalcFN,
    piDetails,
    ivResults,
];
// Routes with sidebar layout to admin role users
export const adminRoutes = [
    notifications,
    createNotifications,
    editNotifications,
    ads,
    createAdvertises,
    editeAdvertises,
    users,
    createUser,
    editeUser,
    entities,
    createEntity,
    editeEntity,
    agents,
];
