// Routes
export const SIGN_IN_PATH = "/auth/sign-in";
export const PW_FORGOT_PATH = "/auth/pwforget";
export const RESET_PATH = "/auth/password_reset"
export const REGISTER_PATH = "/auth/register";
export const SIGN_OUT_PATH = "/auth/sign-out";
export const LANDING_PATH = "/";
export const PRIVACY_PATH = "/privacy";
export const CHANGELOG_PATH = "/changelog";
export const NOTIFICATIONS_PATH = "/admin/notifications";
export const NOTIFY_CREATE_PATH = "/admin/notifications/new";
export const NOTIFY_EDIT_PATH = "/admin/notifications/edit/:id";
export const USERS_PATH = "/admin/users";
export const USERS_CREATE_PATH = "/admin/users/new";
export const USERS_EDIT_PATH = "/admin/users/edit/:email";
export const ENTITIES_PATH = "/admin/entities";
export const ENTITIES_CREATE_PATH = "/admin/entities/new";
export const ENTITIES_EDIT_PATH = "/admin/entities/edit/:id";
export const ADS_PATH = "/admin/advertisers";
export const ADS_CREATE_PATH = "/admin/advertisers/add";
export const ADS_EDIT_PATH = "/admin/advertisers/edit/:id";
export const PROFILE_PATH = "/content/profile";
export const HOME_PATH = "/content/home";
export const GUIDELINE_PATH = "/content/guideline";
export const SEARCH_PATH = "/content/search";
export const SEARCH_DETAIL_PATH = "/content/search/detail/:id";
export const PRODUCTS_PATH = "/content/products";
export const SEARCH_PRODUCTS_PATH = "/content/products/search";
export const DETAILS_PRODUCTS_PATH = "/content/products/search/detail/:id";
export const ATC_PATH = "/content/atc";
export const SEARCH_ATC_PATH = "/content/atc/search";
export const DETAILS_ATC_PATH = "/content/atc/search/detail/:id";
export const APRESC_PATH = "/content/prescritions";
export const CALC_PATH = "/content/calculators";
export const CALC_FN_PATH = "/content/calculators/:id";
export const CIV_PATH = "/content/iv";
export const CIV_RESULTS_PATH = "/content/iv/results";
export const PINJ_PATH = "/content/injections";
export const PINJ_DETAILS_PATH = "/content/injections/details/:id";
export const LAB_PAGE_PATH = "/lab";
export const LAB_ITEM_PAGE_PATH = "/lab/details/:id";
export const AGENTS_PATH = "/admin/labs";
export const DCI_DRUGS_INTERACTIONS = "/content/dci";
export const DCI_INTERACTIONS = "/content/search/detail/:id/dci";
export const DCI_LAB_INTERACTIONS = "/content/lab/detail/:id/dci";
export const EBSCO_PATH = "/content/ebsco";
export const DYNAMED_PATH = "/content/dynamed";

//Queries
export const AGENT_TOKEN_QUERY = "accessToken";
export const EXACT_QUERY = "exact";
export const TOKEN_QUERY = "token";
export const FILTER_QUERY = "filter";
export const SEARCH_QUERY = "q";
export const SUBSTANCES_QUERY = "substances";
export const SOLUTIONS_QUERY = "solutions";
export const SOLUTIONS_TEST_QUERY = "solutionTests";
export const SHOW_INACTIVE_QUERY = "showInactive";
export const TARGET_PLATFORM_QUERY = "targetPlatform";
export const TAB_QUERY = "tab";
export const REBUILD_QUERY = "rebuild";
export const DILUTION_QUERY = "dilution";
export const USE_FUNCTIONS_QUERY = "useFn";
export const IS_DRUG_QUERY = "isDrug"; 
export const ID_QUERY = "id";
export const IS_APP_QUERY = "isApp";
export const DRUG_QUERY = "drug";
export const PRODUCT_QUERY = "product";
export const CLEAR_CACHE_QUERY = "clearCache";
export const NO_FILTER_QUERY = "noFilter";
export const NO_GOING_BACK = "back";

// Default Param values
export const DEFAULT_SKIP = 0;
export const DEFAULT_TAKE = 25;
export const DEFAULT_TAKE_SEARCH = 10;