import React from "react";
import { SIGN_IN_PATH } from "../constants/routes";
import AppContext from "../context/AppContext";
import Loader from "./Loader";
import { Redirect } from "react-router-dom";
import { hasFeatureSubscription } from "../utils/user";

// For routes that can only be accessed by authenticated users
function AuthGuard(props: any) {

  const { children, featureCodes } = props;
  const appContext = React.useContext(AppContext);
  const { user } = appContext;

  if (!appContext.isAuthenticated && !appContext.loading) {
    return <Redirect to={SIGN_IN_PATH} />;
  } else if (appContext.loading) {
    return <Loader />;
  } else if (user && !hasFeatureSubscription(user, featureCodes)) {
    return <Redirect to={SIGN_IN_PATH} />;
  }
  return children;

}

export default AuthGuard;