import React from "react";
import { SvgIcon } from "@mui/material";

const LKIcon = (props: any) => {

    return (
        <SvgIcon {...props} viewBox="0 0 71.6 71.6">
            <path d="M57,69.6H14.6C7.6,69.6,2,64,2,57V14.6C2,7.6,7.6,2,14.6,2H57c7,0,12.6,5.6,12.6,12.6V57C69.6,64,64,69.6,57,69.6z
	 M53.6,7.5H18C12.2,7.5,7.5,12.2,7.5,18v35.6c0,5.8,4.7,10.6,10.6,10.6h35.6c5.8,0,10.6-4.7,10.6-10.6V18
	C64.2,12.2,59.4,7.5,53.6,7.5z M26.3,52.9h-7.6V28.2h7.6V52.9z M22.5,26.3c-2.3,0-3.8-1.7-3.8-3.6c0-2.1,1.5-4,3.8-4
	c2.3,0,3.8,1.7,3.8,3.8C26.3,24.6,25,26.3,22.5,26.3z M52.9,52.9h-7.6V39.8c0-3-1.1-5.3-3.8-5.3c-2.1,0-3,1.7-3.6,3
	c-0.2,0.6-0.2,1.1-0.2,1.9v13.5h-7.6V36.6c0-2.8-0.2-5.3-0.2-7.4h6.8l0.4,3.2h0.2c0.9-1.5,3.2-3.8,7.2-3.8c4.7,0,8.3,3.2,8.3,10.2
	V52.9z"/>
        </SvgIcon>
    );

};

export default LKIcon;