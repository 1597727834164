import React from "react";
import { SvgIcon } from "@mui/material";

const GuidelinesIcon = (props: any) => {

  return (
    <SvgIcon {...props} viewBox="0 0 163 173.1">
      <path d="M32.3,158.8c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h18.4c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4
            v-18h18c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4v-18.4c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6h-18v-18
            c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H34.3c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4v18h-18c-0.5,0-1,0.2-1.4,0.6
            c-0.4,0.4-0.6,0.9-0.6,1.4v18.4c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h18L32.3,158.8z"/>
      <path d="M43.5,173.1c1.2,0,2.4-0.1,3.6-0.2h72.7c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.3-0.1
            c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0.2-0.1,0.3-0.2l41.2-38.2c0.1-0.1,0.2-0.2,0.2-0.3
            c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3l0-0.1V2
            c0-0.5-0.2-1-0.6-1.4C162,0.2,161.5,0,161,0H42.1c-0.5,0-1,0.2-1.4,0.6C40.3,1,40.1,1.5,40.1,2v84.2c-10.7,0.8-20.3,5.5-27.4,12.6
            C4.9,106.7,0,117.6,0,129.6c0,12,4.9,22.9,12.7,30.8C20.6,168.2,31.5,173.1,43.5,173.1z M121.8,166.4v-31.7h34.2L121.8,166.4z
            M15.6,101.7c7.2-7.2,17-11.6,28-11.6c10.9,0,20.8,4.4,28,11.6c7.2,7.2,11.6,17,11.6,28c0,10.9-4.4,20.8-11.6,28
            c-7.2,7.2-17,11.6-28,11.6c-10.9,0-20.8-4.4-28-11.6c-7.2-7.2-11.6-17-11.6-28C4,118.7,8.4,108.8,15.6,101.7z M62.3,34.1
            c0-1.1,0.9-2,2-2h23.8c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H64.3C63.2,36.1,62.3,35.2,62.3,34.1z M62.3,51.7c0-1.1,0.9-2,2-2h64.2
            c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H64.3C63.2,53.7,62.3,52.8,62.3,51.7z M128.5,72.6H64.3c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2h64.2
            c1.1,0,2,0.9,2,2C130.5,71.7,129.6,72.6,128.5,72.6z"/>
    </SvgIcon>
  );

}

export default GuidelinesIcon;