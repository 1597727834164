export class MainFeaturesCode {
  static DashBoard = "DSH";
  static Search = "SHF";
  static Guidelines = "GLF";
  static ATC = "ATCF";
  static ProductsClassification = "PCF";
  static PrescriptionAnalyses = "APF";
  static HealthCalc = "HCF";
  static IVCompability = "IVF";
  static InjectionPreparation = "IPF";
  static Ebsco = "EBSCO";
  static DynaMed = "DYNAMED";
}

export class StudentsCourses {
  static Medicina = "Medicina";
  static Nursing = "Enfermagem";
  static Pharmacy = "Ciências Farmacêuticas";
  static Psychology = "Psicologia";
  static Nutrition = "Nutrição";
  static Dental = "Medicina Dentária";
  static Other = "Outra";
}

// labs
export class Insights {
  static WithInfo = "WithInfo";
  static WithoutInfo = "WithoutInfo";
}