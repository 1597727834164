import React from "react";
import { SvgIcon } from "@mui/material";

const SMonotoringIcon = (props: any) => {

  return (
    <SvgIcon {...props} viewBox="0 0 23 23">
      <path
        d="M 19.624372,2.8957773 A 1.8840142,1.8840142 0 0 0 17.931151,5.5728931 L 17.239447,6.3465789 C 12.719053,-0.90609919 1.5384284,3.1717792 2.8568613,11.714811 l -1.0645288,0.650818 a 0.62808116,0.62808116 0 0 0 0.3268453,1.158828 c 0.2528343,0.03119 0.8494814,-0.437167 1.0649387,-0.543932 1.4987295,4.552213 7.2692565,6.717478 11.4110105,4.107377 l 2.76469,2.764812 a 1.8762825,1.8762825 0 0 0 2.653455,-2.653472 l -2.764812,-2.76469 a 7.8566429,7.8566429 0 0 0 0.587814,-6.8793201 l 0.978528,-1.094515 a 1.8746222,1.8746222 0 1 0 0.80957,-3.5649396 z M 3.9941543,10.398281 C 4.0804919,3.5265434 13.148734,1.3667184 16.358913,7.3315009 l -2.588259,2.8950431 a 1.885876,1.885876 0 0 0 -2.043321,0.416437 L 9.6115505,9.6479299 A 1.8754731,1.8754731 0 1 0 5.9104953,9.8478859 L 4.0246747,11.000844 c -0.018176,-0.198715 -0.03052,-0.399172 -0.03052,-0.602563 z m 8.4958217,1.308271 a 0.62615341,0.62615341 0 0 1 1.19491,0.254775 0.62615341,0.62615341 0 1 1 -1.19491,-0.254775 z M 7.1201974,9.4604679 a 0.62527465,0.62527465 0 0 1 1.2504174,2.9e-5 0.62527465,0.62527465 0 0 1 -1.2504174,-2.9e-5 z M 19.129209,18.083342 a 0.62607005,0.62607005 0 0 1 -0.885323,0.885285 l -2.623097,-2.623211 a 7.8848156,7.8848156 0 0 0 0.885173,-0.88517 z m -2.005671,-7.685061 c -0.181461,7.57637 -10.5239241,9.027568 -12.8434898,1.912222 l 2.2829426,-1.395747 a 1.8906102,1.8906102 0 0 0 2.5147623,-0.135962 l 2.1157829,0.995051 a 1.8752786,1.8752786 0 1 0 3.509874,-0.714388 l 2.171478,-2.4288671 a 6.5328329,6.5328329 0 0 1 0.24865,1.7676911 z m 2.500834,-5.0016692 a 0.62527118,0.62527118 0 0 1 2.7e-5,-1.2504172 0.62527118,0.62527118 0 0 1 -2.7e-5,1.2504172 z" />
    </SvgIcon>
  );

}

export default SMonotoringIcon;