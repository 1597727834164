import React from "react";
import { SvgIcon } from "@mui/material";

const ProductIcon = (props: any) => {

  return (
    <SvgIcon {...props} viewBox="0 0 165.4 169.6">
      <path d="M163.4,165.6h-34.3c1.6-1.4,3-3.1,4.1-5.1c1.8-3.2,2.9-7.1,2.9-11.2V45.2c0-1.3-0.1-2.6-0.3-3.9h7.3
            c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4V2c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H22.3c-0.5,0-1,0.2-1.4,0.6
            C20.5,1,20.3,1.5,20.3,2v37.3c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h7.3c-0.2,1.3-0.3,2.5-0.3,3.9v104.2
            c0,5.5,1.9,10.5,5,14.2c0.6,0.7,1.3,1.4,2,2.1H2c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h44.7h72h44.7c1.1,0,2-0.9,2-2
            C165.4,166.5,164.5,165.6,163.4,165.6z M24.3,37.3V4h116.8v33.3H24.3z M110.4,121.2c-7.1,7.1-16.9,11.5-27.7,11.5
            c-10.8,0-20.6-4.4-27.7-11.5c-7.1-7.1-11.5-16.9-11.5-27.7c0-10.8,4.4-20.6,11.5-27.7c7.1-7.1,16.9-11.5,27.7-11.5
            c10.8,0,20.6,4.4,27.7,11.5c7.1,7.1,11.5,16.9,11.5,27.7C121.9,104.3,117.5,114.1,110.4,121.2z"/>
      <path d="M103.1,85.1h-12v-12c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H76.3c-0.5,0-1,0.2-1.4,0.6
            c-0.4,0.4-0.6,0.9-0.6,1.4v12h-12c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4v12.8c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6
            h12v12c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h12.9c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4v-12h12c0.5,0,1-0.2,1.4-0.6
            c0.4-0.4,0.6-0.9,0.6-1.4V87.1c0-0.5-0.2-1-0.6-1.4C104.2,85.3,103.6,85.1,103.1,85.1z"/>
      <path d="M45.3,11.8c-1.1,0-2,0.9-2,2v14.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V13.8C47.3,12.7,46.4,11.8,45.3,11.8z" />
      <path d="M66,11.8c-1.1,0-2,0.9-2,2v14.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V13.8C67.9,12.7,67.1,11.8,66,11.8z" />
      <path d="M84.7,11.8c-1.1,0-2,0.9-2,2v14.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V13.8C86.6,12.7,85.8,11.8,84.7,11.8z" />
      <path d="M103.3,11.8c-1.1,0-2,0.9-2,2v14.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V13.8C105.3,12.7,104.4,11.8,103.3,11.8z" />
      <path d="M122,11.8c-1.1,0-2,0.9-2,2v14.7c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2V13.8C124,12.7,123.1,11.8,122,11.8z" />
    </SvgIcon>
  );

}

export default ProductIcon;