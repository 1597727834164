import React from "react";
import { SvgIcon } from "@mui/material";

const AnalisePrescIcon = (props: any) => {

    return (
      <SvgIcon {...props} viewBox="0 0 111.8 173.1">
        <path d="M107.2,17.3c-2.9-3-6.9-4.8-11.3-4.8H81.1V9.5c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6H64.7
          c-0.4-1.8-1.3-3.5-2.6-4.7C60.4,1.1,58,0,55.4,0c-2.6,0-5,1.1-6.7,2.8c-1.3,1.3-2.2,2.9-2.6,4.7H31.7c-0.5,0-1,0.2-1.4,0.6
          c-0.4,0.4-0.6,0.9-0.6,1.4v2.9H15.9c-4.4,0-8.4,1.9-11.3,4.8C1.8,20.3,0,24.4,0,28.9v127.8c0,4.5,1.8,8.6,4.6,11.6
          c2.9,3,6.9,4.8,11.3,4.8H96c4.4,0,8.4-1.9,11.3-4.8c2.9-3,4.6-7.1,4.6-11.6V28.9C111.8,24.4,110.1,20.3,107.2,17.3z M82,129.8H28.7
          c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H82c1.1,0,2,0.9,2,2C84,128.9,83.1,129.8,82,129.8z M84,139.5c0,1.1-0.9,2-2,2H28.7
          c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H82C83.1,137.5,84,138.4,84,139.5z M82,118H28.7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H82
          c1.1,0,2,0.9,2,2C84,117.1,83.1,118,82,118z M32.4,57.7c0-0.5,0.2-1,0.6-1.4c0.4-0.4,0.9-0.6,1.4-0.6h12.8V42.9c0-0.5,0.2-1,0.6-1.4
          c0.4-0.4,0.9-0.6,1.4-0.6h13.6c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4v12.8h12.8c0.5,0,1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.4
          v13.6c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6H64.7V86c0,0.5-0.2,1-0.6,1.4c-0.4,0.4-0.9,0.6-1.4,0.6H49.1
          c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4V73.2H34.4c-0.5,0-1-0.2-1.4-0.6c-0.4-0.4-0.6-0.9-0.6-1.4V57.7z M40.8,103.7
          c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2H28.7c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2H40.8z M107.9,156.7c0,3.5-1.3,6.6-3.5,8.8
          c-2.2,2.3-5.1,3.6-8.4,3.6H15.9c-3.3,0-6.2-1.4-8.4-3.6c-2.2-2.3-3.5-5.4-3.5-8.8V28.9c0-3.5,1.4-6.6,3.5-8.8
          c2.2-2.3,5.1-3.6,8.4-3.6h13.8v1.7H14.6c-0.5,0-1,0.2-1.4,0.6c-0.4,0.4-0.6,0.9-0.6,1.4v137c0,0.5,0.2,1,0.6,1.4
          c0.4,0.4,0.9,0.6,1.4,0.6h81.5c0.5,0,1-0.2,1.4-0.6c0.4-0.4,0.6-0.9,0.6-1.4v-137c0-0.5-0.2-1-0.6-1.4c-0.4-0.4-0.9-0.6-1.4-0.6h-15
          v-1.7H96c3.3,0,6.2,1.4,8.4,3.6c2.2,2.3,3.5,5.4,3.5,8.8V156.7z"/>
        <path d="M33.7,11.5h14.2c1.1,0,2-0.9,2-2c0-1.5,0.6-2.9,1.6-3.9c1-1,2.4-1.6,3.9-1.6c1.5,0,2.9,0.6,3.9,1.6
          c1,1,1.6,2.4,1.6,3.9c0,0.5,0.2,1,0.6,1.4c0.4,0.4,0.9,0.6,1.4,0.6h14.2v7.6H33.7V11.5z"/>
      </SvgIcon>
    );

  }

  export default AnalisePrescIcon;
