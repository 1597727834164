import React from "react";
import { create } from "jss";
import {
    StylesProvider,
    jssPreset,
    ThemeProvider as MuiThemeProvider,
} from "@mui/styles";
import "./i18n/config";
import { Helmet } from "react-helmet";
import { ThemeProvider } from "@mui/material/styles";
import { THEMES } from "./constants";
import createTheme from "./theme";
import { User } from "./models/User";
import AppContext from "./context/AppContext";
import Routes from "./routes/Routes";
import { isLoggedIn } from "./services/authService";
import { Theme, Typography } from "@mui/material";
import { getThemeFromUser } from "./utils/user";
import { AnalyseFn } from "./models/AnalyseFn";
import { ANALYSE_FUNCTIONS_COOKIE, PERSONAL_COOKIE } from "./constants/cookies";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { IS_APP_QUERY, PRIVACY_PATH, TAB_QUERY } from "./constants/routes";
import { InterceptRequest } from "./utils/axios";
import { getMinutesPassed } from "./utils/utils";
import { getUser } from "./services/userService";
import axios from "axios";
import { CookiePreferencesModel } from "./models/Cookie";
import { getContainerData } from "./services/contentService";
import { ContainerData } from "./models/ContainerData";
import { Role } from "./models/Role";
import { useLocation } from "react-router";
import { LazyMotion, domAnimation } from "framer-motion";
import "./pdfPreview.css";

const insertionPoint = document.getElementById("jss-insertion-point");

const jss = create({
    ...jssPreset(),
    insertionPoint: insertionPoint ?? "",
});

function App() {
    const theme = { currentTheme: THEMES.SIMPOSIUM };
    const { t }: { t: any } = useTranslation();
    const [user, setUser] = React.useState<User | null>(null);
    const [containerData, setContainerData] =
        React.useState<ContainerData | null>(null);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [userTheme, setUserTheme] = React.useState<Theme>(
        createTheme(theme.currentTheme)
    );
    const [userAnFunc, setUserAnFunc] = React.useState<AnalyseFn[] | null>(
        null
    );
    const location = useLocation();

    const isAppWebview =
        new URLSearchParams(location.search).get(IS_APP_QUERY) !== null;
    const pCookie = localStorage.getItem(PERSONAL_COOKIE);
    const cookiesPref: CookiePreferencesModel =
        pCookie !== null
            ? JSON.parse(pCookie)
            : {
                analytics: {
                    googleAnalytics: true,
                    hotjar: true,
                },
                functional: {
                    analsesFn: true,
                    rememberMe: true,
                },
            };

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        (async () => {
            try {
                const u = await isLoggedIn();
                const c =
                    u.role === Role.Admin ? await getContainerData() : null;
                if (active) {
                    setUser(u);
                    setContainerData(c);
                    setUserTheme(getThemeFromUser(u, userTheme));
                    const anFn = sessionStorage.getItem(
                        ANALYSE_FUNCTIONS_COOKIE
                    );

                    if (anFn !== null) {
                        const userFns: AnalyseFn[] = JSON.parse(anFn);
                        setUserAnFunc(userFns);
                    }

                    setLoading(false);
                }
            } catch (error) {
                if (active) {
                    setLoading(false);
                }
            }
        })();

        return () => {
            active = false;
        };
    }, [loading, userTheme]);

    InterceptRequest(() => {
        if (user !== null) {
            const lastValidation = localStorage.getItem("refreshUser");
            if (lastValidation && getMinutesPassed(lastValidation) >= 5) {
                localStorage.removeItem("refreshUser");
                (async () => {
                    await getUser(user.email).then((res) => {
                        localStorage.setItem(
                            "refreshUser",
                            new Date().toISOString()
                        );
                        setUser(res);
                    });
                    setContainerData(await getContainerData());
                })();
            }
        }
    });

    if (user?.cacheToken != null) {
        axios.defaults.params = {
            cacheToken: user.cacheToken,
        };
    }

    return (
        <LazyMotion features={domAnimation}>
            <AppContext.Provider
                value={{
                    user: user,
                    containerData: containerData,
                    isAuthenticated: !!user,
                    setUser: setUser,
                    loading: loading,
                    theme: userTheme,
                    setTheme: setUserTheme,
                    useFunctions: userAnFunc,
                    setAnalyserFn: setUserAnFunc,
                }}
            >
                <Helmet
                    titleTemplate="%s | Simposium"
                    defaultTitle="Simposium Digital Healthcare"
                >
                    <style>
                        {`
                            body {
                                font-size: ${userTheme.typography.fontSize}px;                
                            }
                            ${userTheme.breakpoints.up("xl")} {
                                body {
                                font-size: ${Number(userTheme.typography.fontSize) + 2}px;
                                }
                            }
                        `}
                    </style>
                </Helmet>
                <StylesProvider jss={jss}>
                    <MuiThemeProvider theme={userTheme}>
                        <ThemeProvider theme={userTheme}>
                            <Routes />
                        </ThemeProvider>
                    </MuiThemeProvider>
                </StylesProvider>
                {!isAppWebview && (
                    <>
                        {cookiesPref.analytics.googleAnalytics && (
                            <Helmet>
                                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-22284531-1"></script>
                                <script>
                                    {`
                                    window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());

                                    gtag('config', 'UA-22284531-1');
                                    `}
                                </script>
                            </Helmet>
                        )}
                        <CookieConsent
                            location="bottom"
                            buttonText={t("I Undestood and accept")}
                            style={{
                                background: userTheme.sidebar.background,
                                padding: `0px ${userTheme.spacing(10)}`,
                                zIndex: 9999,
                            }}
                            buttonStyle={{
                                background: userTheme.palette.primary.main,
                                color: userTheme.palette.primary.contrastText,
                                borderRadius: "4px",
                                padding: "10px 20px",
                                fontSize: "1.4rem",
                                maxWidth: 180,
                            }}
                            acceptOnScroll
                            acceptOnScrollPercentage={25}
                        >
                            <Typography
                                sx={{
                                    fontSize: "1.4rem",
                                    a: {
                                        color: userTheme.palette.primary.main,
                                    },
                                }}
                                children={
                                    <>
                                        {t(
                                            "This portal uses cookies to enhance the user experience"
                                        )}
                                        {"."}
                                        <br />
                                        {t(
                                            "If you continue using it you will accept our"
                                        )}{" "}
                                        <a
                                            href={`${PRIVACY_PATH}?${TAB_QUERY}=2`}
                                        >
                                            {t("Cookies Policy")}
                                        </a>
                                    </>
                                }
                            />
                        </CookieConsent>
                    </>
                )}
            </AppContext.Provider>
        </LazyMotion>
    );
}

export default App;
