import Axios from "axios";

export function Get<T = any>(endpoint: string) {
  return Axios.get<T>(endpoint);
}

export function Post<T = any>(endpoint: string, data: any) {
  return Axios.post<T>(endpoint, data);
}

export function Put<T = any>(endpoint: string, data: any) {
  return Axios.put<T>(endpoint, data);
}

export function Delete<T = any>(endpoint: string) {
  return Axios.delete<T>(endpoint);
}

export function GetBlob<T = any>(endpoint: string) {
  return Axios.get<T>(endpoint, { responseType: 'blob' });
}

export function InterceptRequest(success: any) {
  return Axios.interceptors.request.use(
    (config) => {
      if (success) {
        success();
      }
      return config;
    }, (error) => {
      return Promise.reject(error);
    }
  );
}