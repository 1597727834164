import { City, User } from "../models/User";
import { Delete, Get, Post } from "../utils/axios";
import { AUTH_RESET_PASSWORD_REQUEST } from '../constants/auth';

export function signIn(email: string, password: string): Promise<User> {
  return new Promise((resolve, reject) => {
    Post<User>("/api/auth", { email, password })
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }
        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function isLoggedIn(): Promise<User> {
  return new Promise((resolve, reject) => {
    Get<User>("/api/auth")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function signOut() {
  return Delete("/api/auth");
}

export function userRegister(userData: any): Promise<User> {
  return new Promise((resolve, reject) => {
    Post<User>("/api/users/register", userData).then((response: any) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function forgotPassword(userEmail: string): Promise<any> {
  return new Promise((resolve, reject) => {
    Post<any>("/api/auth/forgot_password", {
      "email": userEmail,
      "redirectUrl": AUTH_RESET_PASSWORD_REQUEST
    }).then((response: any) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function resetPassword(resetData: any): Promise<any> {
  return new Promise((resolve, reject) => {
    Post<any>("/api/auth/password_reset", resetData).then((response: any) => {
      if (response.status === 200) {
        resolve(response.data);
      }
      reject(response.data);
    })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getCities(): Promise<City[]> {
  return new Promise((resolve, reject) => {
    Get<City[]>("/api/content/cities/")
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}

export function getCity(code: string): Promise<City[]> {
  return new Promise((resolve, reject) => {
    Get<City[]>(`/api/content/cities/${code}`)
      .then((response: any) => {
        if (response.status === 200) {
          resolve(response.data);
        }

        reject(response.data);
      })
      .catch((error: any) => {
        reject(error);
      });
  });
}