import { Theme } from "@mui/material";
import { THEMES } from "../constants";
import { Features } from "../models/Features";
import { Role } from "../models/Role";
import { User } from "../models/User";
import createTheme from "../theme";

export const hasFeatureSubscription = (user: User, featureCode: string) =>
  user.role === Role.Admin ||
  user?.entity?.features?.some((f: Features) => f.code === featureCode);


export const getThemeFromUser = (u: User, currentTheme: Theme) => {

  let themeAux: any = null;

  if (u !== undefined && u?.entity?.theme?.name === THEMES.DEFINED) {
    themeAux = currentTheme;

    themeAux.palette.primary.main = u.entity?.theme.primaryMain ?? themeAux.palette.primary.main;
    themeAux.palette.primary.contrastText = u.entity?.theme.primaryContrastText ?? themeAux.palette.primary.contrastText;
    themeAux.palette.secondary.main = u.entity?.theme.secondaryMain ?? themeAux.palette.secondary.main;
    themeAux.palette.secondary.contrastText = u.entity?.theme.secondaryContrastText ?? themeAux.palette.secondary.contrastText;
    themeAux.palette.text.primary = u.entity?.theme.textPrimary ?? themeAux.palette.text.primary;
    themeAux.palette.text.secondary = u.entity?.theme.textSecondary ?? themeAux.palette.text.secondary;
    themeAux.palette.text.disabled = u.entity?.theme.textDisabled ?? themeAux.palette.text.disabled;
    themeAux.sidebar.background = u.entity?.theme.sidebar ?? themeAux.sidebar.background;
    themeAux.sidebar.header.background = u.entity?.theme.sidebar ?? themeAux.sidebar.background;
    themeAux.sidebar.footer.background = u.entity?.theme.sidebar ?? themeAux.sidebar.background;
    themeAux.palette.background.default = u.entity?.theme.background ?? themeAux.palette.background.default;
    themeAux.palette.error.main = u.entity?.theme.errorPrimary ?? themeAux.palette.error.main;
    themeAux.palette.error.light = u.entity?.theme.errorSecondary ?? themeAux.palette.error.light;
    themeAux.palette.warning.main = u.entity?.theme.warningPrimary ?? themeAux.palette.warning.main;
    themeAux.palette.warning.light = u.entity?.theme.warningSecondary ?? themeAux.palette.warning.light;
    themeAux.palette.success.main = u.entity?.theme.successPrimary ?? themeAux.palette.success.main;
    themeAux.palette.success.light = u.entity?.theme.successSecondary ?? themeAux.palette.success.light;

    return themeAux;
  } else if (u !== undefined && u?.entity?.theme) {

    switch (u?.entity?.theme?.name) {
      case THEMES.DARK:
        themeAux = { currentTheme: THEMES.DARK };
        break;
      case THEMES.LIGHT:
        themeAux = { currentTheme: THEMES.LIGHT };
        break;
      case THEMES.BLUE:
        themeAux = { currentTheme: THEMES.BLUE };
        break;
      case THEMES.GREEN:
        themeAux = { currentTheme: THEMES.GREEN };
        break;
      case THEMES.INDIGO:
        themeAux = { currentTheme: THEMES.INDIGO };
        break;
      case THEMES.DEFAULT:
        themeAux = { currentTheme: THEMES.DEFAULT };
        break;
      default:
        themeAux = { currentTheme: THEMES.SIMPOSIUM };
        break;
    }

    const newTheme: Theme = createTheme(themeAux.currentTheme);

    return newTheme;
  }

  return currentTheme;

};