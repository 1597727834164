import React, { useEffect, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    List,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import createTheme from "../../theme";
import styled from "@emotion/styled/macro";
import { useMediaQuery } from "@mui/material";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useTranslation } from "react-i18next";
import { useHistory, Link, useLocation } from "react-router-dom";
import { AppState } from "../../context/AppState";
import { signOut } from "../../services/authService";
import { THEMES } from "../../constants";
import SMonotoringIcon from "../icons/SMonotoringIcon";
import { MainFeaturesCode } from "../../constants/featureCodes";
import Tooltipz from "../Tooltip";
import CustomPopper from "../CustomPopper";
import GroupBox from "../Group";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IVCompatibility from "../../components/icons/IVCompatibility";
import AnalisePrescIcon from "../../components/icons/AnalisePrescIcon";
import { ANALYSE_FUNCTIONS_COOKIE } from "../../constants/cookies";
import { m } from "framer-motion";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import parse from "html-react-parser";
import {
    convertToRGA,
    getFormattedDate,
    getTimePassed,
} from "../../utils/utils";
import { setAsViewed } from "../../services/contentService";
import { withSidebarLayoutRoutes } from "../../routes";
import { getUser, getUserNotifications } from "../../services/userService";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { UserNotifications } from "../../models/User";
import {
    IS_APP_QUERY,
    SIGN_IN_PATH,
    USE_FUNCTIONS_QUERY,
} from "../../constants/routes";

const Wrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
    padding: ${({ theme }: { theme: Theme }) => theme.spacing(3)}
        calc(${({ theme }: { theme: Theme }) => theme.spacing(10)} - 8px)
        ${({ theme }: { theme: Theme }) => theme.spacing(7)};
    button:hover {
        color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    }
    ${({ theme }: { theme: Theme }) =>
        theme.breakpoints.down("xl")}, (max-height: 650px) {
        padding: ${({ theme }: { theme: Theme }) => theme.spacing(3)} 17px
            ${({ theme }: { theme: Theme }) => theme.spacing(7)};
    }
    @media print {
        display: none;
    }
`;

const Analyser = styled(IconButton)`
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.disabled};
    &:hover > svg {
        color: ${({ theme }: { theme: Theme }) =>
            theme.palette.primary.contrastText};
    }
    &.enabled {
        color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
        &:hover {
            background: ${({ theme }: { theme: Theme }) =>
                theme.palette.primary.main};
            & > svg {
                color: ${({ theme }: { theme: Theme }) =>
                    theme.palette.primary.contrastText};
            }
        }
    }
`;

const RemoveBt = styled(IconButton)`
    padding: 2px;
    & > svg {
        width: 15px;
        height: 15px;
    }
`;

const GotoLink = styled(Link)`
    position: absolute;
    width: 30px;
    height: 30px;
    right: 10px;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }: { theme: Theme }) =>
        theme.palette.secondary.main};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    & > svg {
        color: ${({ theme }: { theme: Theme }) =>
            theme.palette.secondary.contrastText};
        max-width: 20px;
        max-height: 20px;
    }
`;

const DisabledBtn = styled(IconButton)`
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    display: flex;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }: { theme: Theme }) =>
        theme.palette.text.disabled};
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    text-decoration: none;
    & > svg {
        color: ${({ theme }: { theme: Theme }) =>
            theme.palette.secondary.contrastText};
        max-width: 20px;
        max-height: 20px;
    }
    &:hover {
        background-color: ${({ theme }: { theme: Theme }) =>
            theme.palette.text.disabled};
    }
`;

const FlexRows = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
    &.container {
        gap: ${({ theme }: { theme: Theme }) => theme.spacing(10)};
        ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
            flex-direction: column;
        }
    }
    &.spaceBetween {
        gap: 0;
        justify-content: space-between;
        margin-top: 20px;
        ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
            gap: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
            flex-direction: column-reverse;
        }
    }
`;

const CAccordion = styled(Accordion)`
    background: none;
    margin: 0px !important;
    &:before {
        content: none;
    }
    .MuiAccordionSummary-root {
        min-height: unset !important;
        padding: 10px 15px;
        & > .MuiAccordionSummary-content {
            margin: 0px;
            justify-content: center;
        }
    }
    .MuiCollapse-root .MuiAccordionDetails-root {
        padding: 0px;
    }
`;

const TopBar: React.FC<{
    appContext: AppState;
    onDrawerToggle: any;
    isDrawerOpen: boolean;
}> = (props) => {
    const theme = useTheme();
    const { t }: { t: any } = useTranslation();
    const matchMD = useMediaQuery(theme.breakpoints.down("md"));
    const history = useHistory();
    const location = useLocation();

    const appContext = props.appContext;
    const onDrawerToggle = props.onDrawerToggle;

    const isAppWebview =
        new URLSearchParams(location.search).get(IS_APP_QUERY) !== null;

    const HasAddedFunctions = () => {
        const [showTooltip, setTooltipVisibility] = useState<boolean>(true);
        const [showPopper, setPopperVisibility] = useState<boolean>(false);
        const [childNode, setChildNode] = useState<HTMLElement | null>(null);

        useEffect(() => {
            return () => {
                setTooltipVisibility(false);
                setPopperVisibility(false);
                setChildNode(null);
            };
        }, []);

        const openPopper = () => {
            if (!showPopper) {
                setTooltipVisibility(false);
                if (childNode === null) {
                    setTimeout(() => setPopperVisibility(!showPopper), 600);
                } else {
                    setPopperVisibility(!showPopper);
                }
            }
        };

        const togglePopper = () => {
            setTooltipVisibility(false);
            setPopperVisibility(!showPopper);
        };

        if (appContext?.useFunctions && appContext?.useFunctions.length) {
            const userFns = appContext?.useFunctions;
            const showNotification = userFns.find((fn) => fn.notify === true);

            const removeFnAn = (anId: any, medId: any) => {
                const fnIndex = userFns.findIndex(
                    (fn) => fn.featureId === anId
                );
                const fnaIndex = userFns[fnIndex].analyse.findIndex(
                    (fna) => fna.medId === medId
                );

                userFns[fnIndex].analyse.splice(fnaIndex, 1);

                if (userFns[fnIndex].analyse.length === 0) {
                    userFns.splice(fnIndex, 1);
                }

                appContext.setAnalyserFn(userFns);
                sessionStorage.setItem(
                    ANALYSE_FUNCTIONS_COOKIE,
                    JSON.stringify(userFns)
                );
                togglePopper();

                history.go(0); // reload page;
            };

            if (showNotification) {
                userFns.map((fn) => (fn.notify = false));
                sessionStorage.setItem(
                    ANALYSE_FUNCTIONS_COOKIE,
                    JSON.stringify(userFns)
                );
                setTimeout(() => setTooltipVisibility(false), 10000);

                if (
                    showNotification.featureId ===
                    MainFeaturesCode.PrescriptionAnalyses
                ) {
                    return (
                        <Tooltipz
                            title={
                                showNotification.analyse.slice(-1)[0].medName +
                                " " +
                                t("added to Clinical Checker")
                            }
                            open={showTooltip}
                        >
                            <Analyser
                                theme={theme}
                                onClick={openPopper}
                                className="enabled"
                            >
                                <SMonotoringIcon />
                            </Analyser>
                        </Tooltipz>
                    );
                } else {
                    return (
                        <Tooltipz
                            title={
                                showNotification.analyse.slice(-1)[0].medName +
                                " " +
                                t("added to IV Compatible")
                            }
                            open={showTooltip}
                        >
                            <Analyser
                                theme={theme}
                                onClick={openPopper}
                                className={
                                    userFns.some(
                                        (fn) =>
                                            fn.featureId ===
                                                MainFeaturesCode.IVCompability &&
                                            fn.analyse.length > 1
                                    )
                                        ? "enabled"
                                        : ""
                                }
                            >
                                <SMonotoringIcon />
                            </Analyser>
                        </Tooltipz>
                    );
                }
            } else {
                return (
                    <>
                        <Analyser
                            theme={theme}
                            ref={setChildNode}
                            onClick={openPopper}
                            className={
                                userFns.some(
                                    (fn) =>
                                        fn.featureId ===
                                            MainFeaturesCode.IVCompability &&
                                        fn.analyse.length > 1
                                ) ||
                                userFns.some(
                                    (fn) =>
                                        fn.featureId ===
                                        MainFeaturesCode.PrescriptionAnalyses
                                )
                                    ? "enabled"
                                    : ""
                            }
                        >
                            <SMonotoringIcon />
                        </Analyser>
                        <CustomPopper
                            show={showPopper}
                            anchor={childNode}
                            placeRight
                            clickAwayFn={togglePopper}
                            maxWidth={250}
                        >
                            {userFns.map((fn) => {
                                return (
                                    <GroupBox
                                        key={fn.featureId}
                                        title={
                                            fn.featureId ===
                                            MainFeaturesCode.PrescriptionAnalyses
                                                ? t("Clinical Checker")
                                                : t("IV Compatibilities")
                                        }
                                        titleBg={theme.palette.primary.main}
                                        style={{
                                            position: "initial",
                                            width: 250,
                                            marginBottom:
                                                userFns.length > 1
                                                    ? theme.spacing(7)
                                                    : 0,
                                            borderColor:
                                                theme.palette.secondary.main,
                                        }}
                                    >
                                        {fn.analyse.map((an) => {
                                            return (
                                                <Grid
                                                    container
                                                    key={an.medId}
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    flexWrap="nowrap"
                                                >
                                                    <Grid
                                                        item
                                                        sx={{
                                                            flexGrow: 1,
                                                        }}
                                                    >
                                                        <Tooltipz
                                                            title={an.medName}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    width: 190,
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    overflow:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                }}
                                                            >
                                                                {`${
                                                                    an.medName
                                                                }${
                                                                    fn.featureId ===
                                                                    MainFeaturesCode.IVCompability
                                                                        ? an.isMedSolution
                                                                            ? ` (${t(
                                                                                  "Solution"
                                                                              )})`
                                                                            : ` (${t(
                                                                                  "Substance"
                                                                              )})`
                                                                        : ""
                                                                }`}
                                                            </Typography>
                                                        </Tooltipz>
                                                    </Grid>
                                                    <Grid item>
                                                        <RemoveBt
                                                            onClick={() =>
                                                                removeFnAn(
                                                                    fn.featureId,
                                                                    an.medId
                                                                )
                                                            }
                                                        >
                                                            <RemoveCircleIcon
                                                                color="warning"
                                                                fontSize="small"
                                                            />
                                                        </RemoveBt>
                                                    </Grid>
                                                </Grid>
                                            );
                                        })}
                                        {fn.featureId ===
                                            MainFeaturesCode.IVCompability &&
                                        fn.analyse.length < 2 ? (
                                            <Tooltipz
                                                title={t(
                                                    "IV compatibility analysis requires at least 2 substances/solutions"
                                                )}
                                            >
                                                <DisabledBtn theme={theme}>
                                                    <IVCompatibility />
                                                </DisabledBtn>
                                            </Tooltipz>
                                        ) : (
                                            <GotoLink
                                                theme={theme}
                                                to={`${fn.featurePath}?${USE_FUNCTIONS_QUERY}=1`}
                                            >
                                                {fn.featureId ===
                                                MainFeaturesCode.PrescriptionAnalyses ? (
                                                    <AnalisePrescIcon />
                                                ) : (
                                                    <IVCompatibility />
                                                )}
                                            </GotoLink>
                                        )}
                                    </GroupBox>
                                );
                            })}
                        </CustomPopper>
                    </>
                );
            }
        }

        return null;
    };

    const handleSignOut = async () => {
        if (appContext.isAuthenticated) {
            appContext.loading = true;
            appContext.setUser(null);
            signOut().finally(() => {
                const spTheme = { currentTheme: THEMES.SIMPOSIUM };
                sessionStorage.clear();
                appContext.setAnalyserFn(null);
                appContext.setTheme(createTheme(spTheme.currentTheme));
                appContext.setUser(null);
                localStorage.removeItem("refreshUser");
                appContext.loading = false;
                history.push(SIGN_IN_PATH);
            });
        }
    };

    const Notification = () => {
        const [notifications, setNotifications] = useState<
            UserNotifications[] | null
        >(null);

        const updateNotifications = async () => {
            if (appContext.user !== null) {
                await getUserNotifications(appContext.user.email).then(
                    (res: UserNotifications[]) => {
                        setNotifications(res);
                    }
                ).catch((err) => {
                    setNotifications(null);
                });
            }
        };
        
        const totalNots = notifications
            ? notifications.filter((n) => n.viewed === null).length
            : 0;
        const [showNotifierPopper, setNotifierPopperVisibility] =
            useState<boolean>(false);
        const [childNotifierNode, setChildNotifierNode] =
            useState<HTMLElement | null>(null);
        const [viewAll, setViewAll] = useState<boolean>(false);
        const [toggleDismissTooltip, setToggleDismissTooltip] = useState<
            boolean | null
        >(false);

        useEffect(() => {
            updateNotifications();
            return () => {
                setNotifierPopperVisibility(false);
                setChildNotifierNode(null);
            };
        }, []);

        useEffect(() => {
            if (showNotifierPopper === true && toggleDismissTooltip !== null) {
                setToggleDismissTooltip(true);
                setTimeout(() => {
                    setToggleDismissTooltip(null);
                }, 5000);
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [showNotifierPopper]);

        const openNotifierPopper = () => {
            if (!showNotifierPopper) {
                if (childNotifierNode === null) {
                    setTimeout(
                        () => setNotifierPopperVisibility(!showNotifierPopper),
                        600
                    );
                } else {
                    setNotifierPopperVisibility(!showNotifierPopper);
                }
            }
        };

        const handleDismissInfoTooltip = () => {
            setToggleDismissTooltip(null);
        };

        const handleDismiss = async (id: number) => {
            try {
                await setAsViewed(id).then(async (res: any) => {
                    const updateUser = await getUser(appContext.user!.email);
                    appContext.setUser(updateUser);
                });
            } catch (e: any) {
                console.log(e);
            }
        };

        const handleDismissAll = async () => {
            const newNotes = notifications!.filter(
                (note) => note.viewed === null
            );

            if (newNotes) {
                for (const note of newNotes) {
                    await handleDismiss(note.id);
                }
            }
        };

        const handleRedirectLink = (link: string, id: number) => {
            const isRelativePath = withSidebarLayoutRoutes.some((p) => {
                const testLink = `${window.location.origin}${p.path}`;
                return testLink === link || p.path === link;
            });

            if (isRelativePath) {
                history.push(link);
            } else {
                window.open(link, "_blank");
            }

            handleDismiss(id);
        };

        return (
            <>
                <IconButton
                    aria-label={t("Notifications")}
                    color="inherit"
                    ref={setChildNotifierNode}
                    onClick={() => {
                        openNotifierPopper();
                    }}
                >
                    <Badge color="secondary" badgeContent={totalNots}>
                        <NotificationsIcon
                            color={showNotifierPopper ? "primary" : "inherit"}
                        />
                    </Badge>
                </IconButton>
                {
                    <m.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.6 }}
                    >
                        <CustomPopper
                            show={showNotifierPopper}
                            anchor={childNotifierNode}
                            placeRight
                            clickAwayFn={() => {
                                setNotifierPopperVisibility(false);
                            }}
                            maxWidth={360}
                            fullWidth
                            noBg
                        >
                            <FlexRows
                                theme={theme}
                                sx={{
                                    justifyContent: "space-between !important",
                                    alignItems: "flex-start !important",
                                    padding: "15px 15px 5px",
                                    boxShadow:
                                        totalNots > 0
                                            ? `0px 2px 2px -2px ${convertToRGA(
                                                  theme.palette.primary.main,
                                                  0.4
                                              )}`
                                            : "none",
                                }}
                            >
                                <Box>
                                    <Typography
                                        variant="h3"
                                        component="h3"
                                        color="secondary"
                                        sx={{
                                            [theme.breakpoints.up("xl")]: {
                                                fontSize:
                                                    Number(
                                                        theme.typography.h3
                                                            .fontSize!
                                                    ) + 2,
                                            },
                                        }}
                                    >
                                        {t("Notifications")}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        component="span"
                                        fontSize={10}
                                        color={theme.palette.text.primary}
                                    >
                                        {`${t("You have")} ${totalNots} ${t(
                                            "unread messages"
                                        )}`}
                                    </Typography>
                                </Box>
                                {totalNots > 0 && (
                                    <Tooltipz
                                        title={t(
                                            "Click here to mark all as read or on each notification icon in the right"
                                        )}
                                        open={Boolean(toggleDismissTooltip)}
                                    >
                                        <Box>
                                            <Tooltipz
                                                title={t("Mark all as read")}
                                                onOpen={
                                                    handleDismissInfoTooltip
                                                }
                                            >
                                                <IconButton
                                                    edge="start"
                                                    size="small"
                                                    onClick={handleDismissAll}
                                                >
                                                    <BeenhereIcon
                                                        fontSize="small"
                                                        color="success"
                                                    />
                                                </IconButton>
                                            </Tooltipz>
                                        </Box>
                                    </Tooltipz>
                                )}
                            </FlexRows>
                            {notifications && notifications!.length > 0 && (
                                <Box
                                    sx={{
                                        color: theme.palette.text.primary,
                                    }}
                                >
                                    <List
                                        dense
                                        sx={{
                                            overflow: "auto",
                                            maxHeight: "30vh",
                                            padding: 0,
                                        }}
                                    >
                                        {notifications!
                                            .filter(
                                                (note) => note.viewed === null
                                            )
                                            .sort(
                                                (
                                                    a: UserNotifications,
                                                    b: UserNotifications
                                                ) =>
                                                    new Date(
                                                        b.created
                                                    ).getTime() -
                                                    new Date(
                                                        a.created
                                                    ).getTime()
                                            )
                                            .map((note) => (
                                                <Box
                                                    key={`n_new_${note.id}`}
                                                    sx={{
                                                        position: "relative",
                                                        overflow: "hidden",
                                                        boxShadow:
                                                            "0px 1px 2px -1px rgba(0,0,0,0.1)",
                                                        transition:
                                                            "all 0.6s ease",
                                                        padding: "10px 15px",
                                                        "&:hover": {
                                                            background:
                                                                convertToRGA(
                                                                    theme
                                                                        .palette
                                                                        .text
                                                                        .disabled,
                                                                    0.4
                                                                ),
                                                            "& .markAsRead": {
                                                                bottom: -10,
                                                            },
                                                        },
                                                        "&:last-of-type": {
                                                            boxShadow:
                                                                "none !important",
                                                        },
                                                    }}
                                                >
                                                    <FlexRows
                                                        theme={theme}
                                                        sx={{
                                                            justifyContent:
                                                                "space-between !important",
                                                            alignItems:
                                                                "flex-end !important",
                                                            marginBottom: "5px",
                                                        }}
                                                    >
                                                        <FlexRows
                                                            theme={theme}
                                                            sx={{
                                                                columnGap:
                                                                    "4px",
                                                                alignItems:
                                                                    "center !important",
                                                            }}
                                                        >
                                                            <AccessTimeIcon
                                                                color="disabled"
                                                                sx={{
                                                                    width: 12,
                                                                    height: 12,
                                                                }}
                                                            />
                                                            <Typography
                                                                fontWeight="fontWeightLight"
                                                                fontStyle="italic"
                                                                component="span"
                                                                fontSize={11}
                                                                color="disabled"
                                                                sx={{
                                                                    display:
                                                                        "block",
                                                                    textAlign:
                                                                        "right",
                                                                }}
                                                            >
                                                                {getTimePassed(
                                                                    note.created,
                                                                    t
                                                                )}
                                                            </Typography>
                                                        </FlexRows>
                                                        {note.redirectUrl !==
                                                            "" && (
                                                            <Button
                                                                variant="text"
                                                                size="small"
                                                                color="secondary"
                                                                onClick={() => {
                                                                    handleRedirectLink(
                                                                        note.redirectUrl,
                                                                        note.id
                                                                    );
                                                                }}
                                                                sx={{
                                                                    justifyContent:
                                                                        "flex-end",
                                                                    padding: 0,
                                                                    minWidth:
                                                                        "unset",
                                                                    "&:hover": {
                                                                        "& > span":
                                                                            {
                                                                                opacity: 1,
                                                                                width: 25,
                                                                            },
                                                                    },
                                                                }}
                                                            >
                                                                <Typography
                                                                    component="span"
                                                                    sx={{
                                                                        textTransform:
                                                                            "uppercase",
                                                                        fontSize: 9,
                                                                        lineHeight: 1,
                                                                        transition:
                                                                            "all 0.6s ease",
                                                                        opacity: 0,
                                                                        width: 0,
                                                                    }}
                                                                >
                                                                    {t(
                                                                        "View more"
                                                                    )}
                                                                </Typography>
                                                                <ExitToAppIcon
                                                                    sx={{
                                                                        width: 18,
                                                                        height: 18,
                                                                    }}
                                                                />
                                                            </Button>
                                                        )}
                                                    </FlexRows>
                                                    <FlexRows
                                                        theme={theme}
                                                        sx={{
                                                            columnGap: "10px",
                                                        }}
                                                    >
                                                        {note.imageUrl && (
                                                            <Avatar
                                                                variant="rounded"
                                                                alt={note.title}
                                                                src={
                                                                    note.imageUrl
                                                                }
                                                                sx={{
                                                                    width: 50,
                                                                    height: 50,
                                                                    boxShadow:
                                                                        "-2px 2px 5px rgba(0,0,0,0.3)",
                                                                }}
                                                            />
                                                        )}
                                                        <Box
                                                            sx={{
                                                                position:
                                                                    "relative",
                                                                flexGrow: 1,
                                                            }}
                                                        >
                                                            {note.title && (
                                                                <Typography
                                                                    component="h4"
                                                                    variant="h4"
                                                                    fontWeight="fontWeightMedium"
                                                                >
                                                                    {note.title}
                                                                </Typography>
                                                            )}
                                                            {note.description && (
                                                                <Box
                                                                    sx={{
                                                                        marginRight: 10,
                                                                        "& > *":
                                                                            {
                                                                                margin: 0,
                                                                            },
                                                                        "ul, ol":
                                                                            {
                                                                                paddingLeft:
                                                                                    "25px",
                                                                            },
                                                                        a: {
                                                                            color: theme
                                                                                .palette
                                                                                .secondary
                                                                                .main,
                                                                        },
                                                                        img: {
                                                                            width: "100%",
                                                                        },
                                                                    }}
                                                                >
                                                                    {parse(
                                                                        note.description
                                                                    )}
                                                                </Box>
                                                            )}
                                                            <Tooltipz
                                                                title={t(
                                                                    "Mark as read"
                                                                )}
                                                            >
                                                                <IconButton
                                                                    edge="start"
                                                                    size="small"
                                                                    className="markAsRead"
                                                                    onClick={() =>
                                                                        handleDismiss(
                                                                            note.id
                                                                        )
                                                                    }
                                                                    sx={{
                                                                        position:
                                                                            "absolute",
                                                                        bottom: -40,
                                                                        right: -5,
                                                                        transition:
                                                                            "all 0.6s ease",
                                                                    }}
                                                                >
                                                                    <MarkChatReadIcon
                                                                        fontSize="small"
                                                                        color="success"
                                                                    />
                                                                </IconButton>
                                                            </Tooltipz>
                                                        </Box>
                                                    </FlexRows>
                                                </Box>
                                            ))}
                                    </List>
                                    {notifications!.filter(
                                        (note) => note.viewed !== null
                                    ).length > 0 && (
                                        <CAccordion
                                            expanded={viewAll}
                                            onChange={() =>
                                                setViewAll(
                                                    (prevState) => !prevState
                                                )
                                            }
                                            sx={{
                                                boxShadow: `0px -2px 2px -2px ${convertToRGA(
                                                    theme.palette.primary.main,
                                                    0.4
                                                )}`,
                                            }}
                                        >
                                            <AccordionSummary>
                                                <Typography
                                                    color="primary"
                                                    sx={{
                                                        transition:
                                                            "all 0.6s ease",
                                                    }}
                                                >
                                                    {viewAll
                                                        ? t(
                                                              "Hide read notifications"
                                                          )
                                                        : t(
                                                              "View read notifications"
                                                          )}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                sx={{
                                                    background: convertToRGA(
                                                        theme.palette.text
                                                            .disabled,
                                                        0.1
                                                    ),
                                                }}
                                            >
                                                <List
                                                    dense
                                                    sx={{
                                                        overflow: "auto",
                                                        maxHeight: "30vh",
                                                        padding: 0,
                                                    }}
                                                >
                                                    {notifications!
                                                        .filter(
                                                            (note) =>
                                                                note.viewed !==
                                                                null
                                                        )
                                                        .sort(
                                                            (
                                                                a: UserNotifications,
                                                                b: UserNotifications
                                                            ) =>
                                                                new Date(
                                                                    b.created
                                                                ).getTime() -
                                                                new Date(
                                                                    a.created
                                                                ).getTime()
                                                        )
                                                        .map((note) => (
                                                            <Box
                                                                key={`n_old_${note.id}`}
                                                                sx={{
                                                                    position:
                                                                        "relative",
                                                                    overflow:
                                                                        "hidden",
                                                                    boxShadow:
                                                                        "0px 1px 2px -1px rgba(0,0,0,0.1)",
                                                                    transition:
                                                                        "all 0.6s ease",
                                                                    padding:
                                                                        "10px 15px",
                                                                    "&:hover": {
                                                                        background:
                                                                            convertToRGA(
                                                                                theme
                                                                                    .palette
                                                                                    .text
                                                                                    .disabled,
                                                                                0.4
                                                                            ),
                                                                    },
                                                                    "&:last-of-type":
                                                                        {
                                                                            boxShadow:
                                                                                "none !important",
                                                                        },
                                                                }}
                                                            >
                                                                <FlexRows
                                                                    theme={
                                                                        theme
                                                                    }
                                                                    sx={{
                                                                        justifyContent:
                                                                            "space-between !important",
                                                                        alignItems:
                                                                            "flex-end !important",
                                                                    }}
                                                                >
                                                                    <FlexRows
                                                                        theme={
                                                                            theme
                                                                        }
                                                                        sx={{
                                                                            columnGap:
                                                                                "4px",
                                                                            alignItems:
                                                                                "center !important",
                                                                        }}
                                                                    >
                                                                        <AccessTimeIcon
                                                                            color="disabled"
                                                                            sx={{
                                                                                width: 12,
                                                                                height: 12,
                                                                            }}
                                                                        />
                                                                        <Typography
                                                                            fontWeight="fontWeightLight"
                                                                            fontStyle="italic"
                                                                            component="span"
                                                                            fontSize={
                                                                                11
                                                                            }
                                                                            color="disabled"
                                                                            sx={{
                                                                                display:
                                                                                    "block",
                                                                                textAlign:
                                                                                    "right",
                                                                            }}
                                                                        >
                                                                            {getTimePassed(
                                                                                note.created,
                                                                                t
                                                                            )}
                                                                        </Typography>
                                                                    </FlexRows>
                                                                    {note.redirectUrl !==
                                                                        "" && (
                                                                        <Button
                                                                            variant="text"
                                                                            size="small"
                                                                            color="secondary"
                                                                            onClick={() => {
                                                                                handleRedirectLink(
                                                                                    note.redirectUrl,
                                                                                    note.id
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                justifyContent:
                                                                                    "flex-end",
                                                                                padding: 0,
                                                                                minWidth:
                                                                                    "unset",
                                                                                "&:hover":
                                                                                    {
                                                                                        "& > span":
                                                                                            {
                                                                                                opacity: 1,
                                                                                                width: 25,
                                                                                            },
                                                                                    },
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                component="span"
                                                                                sx={{
                                                                                    textTransform:
                                                                                        "uppercase",
                                                                                    fontSize: 9,
                                                                                    lineHeight: 1,
                                                                                    transition:
                                                                                        "all 0.6s ease",
                                                                                    opacity: 0,
                                                                                    width: 0,
                                                                                }}
                                                                            >
                                                                                {t(
                                                                                    "View more"
                                                                                )}
                                                                            </Typography>
                                                                            <ExitToAppIcon
                                                                                sx={{
                                                                                    width: 18,
                                                                                    height: 18,
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    )}
                                                                </FlexRows>
                                                                <FlexRows
                                                                    theme={
                                                                        theme
                                                                    }
                                                                    sx={{
                                                                        columnGap:
                                                                            "10px",
                                                                    }}
                                                                >
                                                                    {note.imageUrl && (
                                                                        <Avatar
                                                                            variant="rounded"
                                                                            alt={
                                                                                note.title
                                                                            }
                                                                            src={
                                                                                note.imageUrl
                                                                            }
                                                                            sx={{
                                                                                width: 50,
                                                                                height: 50,
                                                                                boxShadow:
                                                                                    "-2px 2px 5px rgba(0,0,0,0.3)",
                                                                            }}
                                                                        />
                                                                    )}
                                                                    <Box
                                                                        sx={{
                                                                            position:
                                                                                "relative",
                                                                            flexGrow: 1,
                                                                        }}
                                                                    >
                                                                        {note.title && (
                                                                            <Typography
                                                                                component="h4"
                                                                                variant="h4"
                                                                                fontWeight="fontWeightMedium"
                                                                            >
                                                                                {
                                                                                    note.title
                                                                                }
                                                                            </Typography>
                                                                        )}
                                                                        {note.description && (
                                                                            <Box
                                                                                sx={{
                                                                                    marginRight: 10,
                                                                                    "& > *":
                                                                                        {
                                                                                            margin: 0,
                                                                                        },
                                                                                    "ul, ol":
                                                                                        {
                                                                                            paddingLeft:
                                                                                                "25px",
                                                                                        },
                                                                                    a: {
                                                                                        color: theme
                                                                                            .palette
                                                                                            .secondary
                                                                                            .main,
                                                                                    },
                                                                                    img: {
                                                                                        width: "100%",
                                                                                    },
                                                                                }}
                                                                            >
                                                                                {parse(
                                                                                    note.description
                                                                                )}
                                                                            </Box>
                                                                        )}
                                                                        <Tooltipz
                                                                            title={`${t(
                                                                                "Read on"
                                                                            )} ${getFormattedDate(
                                                                                note.viewed!
                                                                            )}`}
                                                                        >
                                                                            <IconButton
                                                                                edge="start"
                                                                                size="small"
                                                                                className="markAsRead"
                                                                                sx={{
                                                                                    position:
                                                                                        "absolute",
                                                                                    bottom: -10,
                                                                                    right: -5,
                                                                                }}
                                                                            >
                                                                                <MarkChatReadIcon
                                                                                    fontSize="small"
                                                                                    color="primary"
                                                                                />
                                                                            </IconButton>
                                                                        </Tooltipz>
                                                                    </Box>
                                                                </FlexRows>
                                                            </Box>
                                                        ))}
                                                </List>
                                            </AccordionDetails>
                                        </CAccordion>
                                    )}
                                </Box>
                            )}
                        </CustomPopper>
                    </m.div>
                }
            </>
        );
    };

    if (isAppWebview) {
        return <Box style={{ padding: 20 }}></Box>;
    }

    return (
        <Wrapper theme={theme}>
            {matchMD ? (
                <IconButton
                    aria-label={t("Open Menu")}
                    color="inherit"
                    onClick={onDrawerToggle}
                >
                    <MenuIcon />
                </IconButton>
            ) : (
                <span></span>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    transition: "opacity 0.5s ease",
                    opacity: props.isDrawerOpen ? 0 : 1,
                }}
            >
                <HasAddedFunctions />
                <Notification />
                <IconButton
                    aria-label={t("Sign out")}
                    color="inherit"
                    onClick={handleSignOut}
                >
                    <PowerSettingsNewIcon />
                </IconButton>
            </Box>
        </Wrapper>
    );
};

export default TopBar;
