import React from "react";
import { SvgIcon } from "@mui/material";

const FBIcon = (props: any) => {

    return (
        <SvgIcon {...props} viewBox="0 0 67.649 67.649">
            <path d="M55.036,67.649H12.613C5.647,67.649,0,62.002,0,55.036V12.613C0,5.647,5.647,0,12.613,0h42.423
	c6.966,0,12.613,5.647,12.613,12.613v42.423C67.649,62.002,62.002,67.649,55.036,67.649z M51.601,5.478H16.048
	c-5.838,0-10.57,4.732-10.57,10.57v35.552c0,5.838,4.732,10.57,10.57,10.57h35.552c5.838,0,10.57-4.732,10.57-10.57V16.048
	C62.171,10.21,57.439,5.478,51.601,5.478z M45.126,19.205h-3.767c-3.128,0-4.318,2.348-4.317,4.526v5.319h8.082l-1.788,7.786h-6.295
	v19.813h-9.442V36.837h-6.295v-7.79h6.295v-6.452C27.599,15.784,31.414,11,39.452,11l5.674,0.006V19.205z"/>
        </SvgIcon>
    );

};

export default FBIcon;