const breakpoints = {
  values: {
    xs: 640,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1440,
  },
};

export default breakpoints;
