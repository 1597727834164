import React from "react";
import {
  Box,
  IconButton,
  Link,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "@emotion/styled/macro";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FBIcon from "../icons/socials/FBIcon";
import LKIcon from "../icons/socials/LKIcon";
import MapIcon from "@mui/icons-material/Map";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import AppContext from "../../context/AppContext";
import { Role } from "../../models/Role";
import {
  AGENT_TOKEN_QUERY,
  CHANGELOG_PATH,
  DCI_DRUGS_INTERACTIONS,
  IS_APP_QUERY,
  PRIVACY_PATH,
} from "../../constants/routes";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
    flex-direction: column;
    justify-content: center;
  }
  &.onlycopy {
    justify-content: end !important;
  }
`;

const FooterMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  & > a {
    text-decoration: none;
    max-width: 150px;
    line-height: 1.1;
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
    transition: color 0.6s ease-in-out;
    &.active,
    &:hover {
      color: ${({ theme }: { theme: Theme }) =>
        theme.palette.primary.main} !important;
    }
    &:after {
      padding: 0px 10px;
      content: "|";
      color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
    }
    &:last-of-type,
    &:only-child {
      &:after {
        content: none !important;
      }
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("sm")} {
      max-width: 180px;
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
      &:after {
        padding: 0px 7px;
      }
    }
  }
`;

const CopyrightDev = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > span {
    color: ${({ theme }: { theme: Theme }) => theme.palette.text.primary};
    padding-right: 10px;
  }
  & > a {
    text-decoration: none;
    & > img {
      width: auto;
      height: 40px;
    }
  }
`;

const SocialWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  & > a {
    text-decoration: none;
    line-height: 1;
    font-size: 0px;
    color: ${({ theme }: { theme: Theme }) => theme.palette.secondary.main};
    transition: color 0.6s ease-in-out;
    &:hover {
      color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    }
  }
  &.blueBg > a:hover {
    color: ${({ theme }: { theme: Theme }) =>
      theme.palette.secondary.contrastText}; !important;
  }
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
    justify-content: center;
    margin: 0px auto 7px;
  }
`;

const ContactsFlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin-bottom: 2px;
  & > button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      color: ${({ theme }: { theme: Theme }) => theme.palette.secondary.main};
      width: auto;
      height: 1.4rem;
      transition: all 0.6s ease;
    }
    &:hover > svg {
      color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    }
  }
  p,
  a {
    display: block;
    text-align: left;
    font-weight: ${({ theme }: { theme: Theme }) =>
      theme.typography.fontWeightMedium};
    font-size: 1.3rem;
    line-height: 1.4rem;
    color: ${({ theme }: { theme: Theme }) => theme.palette.secondary.main};
    transition: all 0.6s ease-in-out;
    text-decoration: none;
  }
  a:hover {
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    text-decoration: none;
  }
  ${({ theme }: { theme: Theme }) => theme.breakpoints.down("xs")} {
    column-gap: 8px;
    margin-bottom: 5px;
    & > button {
      & > svg {
        height: 1.3rem;
      }
    }
    p,
    a {
      font-size: 1.2rem;
      line-height: 1.3rem;
    }
  }
`;

const Hash = ({
  title,
  description,
}: {
  description: string | undefined | JSX.Element;
  title?: string | undefined;
}) => (
  <Typography
    component="span"
    hidden={description == null}
    sx={{
      fontSize: 12,
      lineHeight: 1,
      "& > i": {
        fontWeight: 300,
        fontStyle: "normal",
      },
      "& > i > a": {
        textDecoration: "none",
      },
    }}
    children={
      <>
        {title ? `${title}/` : ""}
        <i>{description}</i>
      </>
    }
  />
);

const Footer = (props: {
  mw?: boolean;
  showContacts?: boolean;
  showSocial?: boolean;
  showBg?: boolean;
  isToken?: boolean;
}) => {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const appContext = React.useContext(AppContext);
  const user = appContext?.user;
  const containerData = appContext?.containerData;
  const imageId = containerData?.imageId?.substring(0, 8);
  const dockerId = containerData?.dockerId?.substring(0, 8);
  const serviceUrl = containerData?.serviceUrl;
  const taskUrl = containerData?.taskUrl;
  const location = useLocation();
  const token = new URLSearchParams(location.search).get(AGENT_TOKEN_QUERY);

  const isAppWebview = new URLSearchParams(location.search).get(IS_APP_QUERY) !== null;

  if (isAppWebview) {
    return (<Box style={{ padding: 20 }}></Box>);
  }

  return (
    <Box
      sx={{
        width: "100%",
        background: props.showBg ? "#FAFAFA" : "none",
        boxShadow: props.showBg ? "2px -2px 5px rgba(0,0,0,0.2)" : "none",
        paddingTop: props.showBg ? `${theme.spacing(10)}` : "10px",
        "@media print": {
          display: "none",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          maxWidth: props.mw ? theme.breakpoints.values.lg : "none",
          margin: `0px auto`,
          width: "100%",
          px: 10,
        }}
      >
        {(props.showSocial || props.showContacts) && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "end",
              width: "100%",
              mb: "10px",
              "@media only screen and (max-width: 820px)": {
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                mb: "8px",
              },
            }}
          >
            {props.showContacts ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: 5,
                  justifyContent: "center",
                  alignItems: "start",
                  alignSelf: "start",
                }}
              >
                <ContactsFlexBox theme={theme}>
                  <IconButton
                    onClick={() => (window.location.href = "tel:+351217990760")}
                  >
                    <LocalPhoneIcon />
                  </IconButton>
                  <Link href="tel:+351217990760" rel="noreferrer">
                    {"+351 217 990 760"}
                  </Link>
                </ContactsFlexBox>
                <ContactsFlexBox theme={theme}>
                  <IconButton
                    onClick={() =>
                      (window.location.href = "mailto:geral@simposium.pt")
                    }
                  >
                    <EmailIcon />
                  </IconButton>
                  <Link href="mailto:geral@simposium.pt" rel="noreferrer">
                    {"geral@simposium.pt"}
                  </Link>
                </ContactsFlexBox>
                <ContactsFlexBox theme={theme}>
                  <IconButton
                    onClick={() =>
                      window.open(
                        "https://goo.gl/maps/hwuaXmH1mGcDirAx9",
                        "_blank"
                      )
                    }
                  >
                    <MapIcon />
                  </IconButton>
                  <Typography
                    children={
                      <>
                        <Link
                          href="https://goo.gl/maps/hwuaXmH1mGcDirAx9"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {"Rua Cidade de Bolama, 14E - 406 | 1800-079 Lisboa"}
                        </Link>
                      </>
                    }
                  />
                </ContactsFlexBox>
              </Box>
            ) : (
              <Box />
            )}
            {props.showSocial && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  alignItems: "center",
                  columnGap: 5,
                }}
              >
                <Typography
                  fontWeight={"fontWeightLight"}
                  fontSize={14}
                  textAlign={"right"}
                  color={"secondary"}
                  sx={{
                    [theme.breakpoints.down("xl")]: {
                      fontSize: 12
                    }
                  }}
                >
                  {t("Follow us at")}
                </Typography>
                <SocialWrapper theme={theme}>
                  <Link
                    href="https://www.facebook.com/profile.php?id=100087242820272"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FBIcon />
                  </Link>
                  {
                  /*
                  <Link
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <INIcon />
                  </Link>
                  */
                  <Link
                    href="https://www.linkedin.com/company/simposium-advanced-healthcare"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LKIcon />
                  </Link>
                  /*
                  <Link
                    href="https://youtube.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YTIcon />
                  </Link>
                  */
                  }
                </SocialWrapper>
              </Box>
            )}
          </Box>
        )}
        <Wrapper theme={theme} id="footer">
          <FooterMenu theme={theme}>
            {(user !== null || props.isToken) && (
              <NavLink to={`${DCI_DRUGS_INTERACTIONS}${token ? `?${AGENT_TOKEN_QUERY}=${token}` : ""}`}>{t("References")}</NavLink>
            )}
            <NavLink to={PRIVACY_PATH}>{t("Legal Notice")}</NavLink>
            {!props.showContacts && (
              <NavLink to={CHANGELOG_PATH} target="_blank">
                {t("Changelog")}
              </NavLink>
            )}
          </FooterMenu>
          <CopyrightDev theme={theme}>
            <span>&copy; {new Date().getFullYear()}</span>
            <Link
              href="https://www.simposiumdigital.com/"
              target="_blank"
              rel="noreferrer"
              sx={{
                fontSize: 0,
              }}
            >
              <img
                src="/static/img/spdh_logo.webp"
                alt="Simposium Digital Heathcare"
              />
            </Link>
          </CopyrightDev>
        </Wrapper>
      </Box>
      {user?.role === Role.Admin && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            columnGap: "10px",
            maxWidth: props.mw ? theme.breakpoints.values.lg : "none",
            margin: `0px auto`,
            width: "100%",
            px: props.mw ? 10 : 0,
          }}
        >
          <Hash
            description={
              <Link target="_blank" rel="noreferrer" href={serviceUrl}>
                Service
              </Link>
            }
          />
          <Hash
            title="Task"
            description={
              <Link target="_blank" rel="noreferrer" href={taskUrl}>
                {dockerId}
              </Link>
            }
          />
          <Hash title="Image" description={imageId} />
        </Box>
      )}
    </Box>
  );
};

export default Footer;
