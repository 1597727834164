import { lazy } from "react";

/* GUEST PAGES */
export const LazyLanding = lazy(() => import("../pages/Landing"));
export const LazyPolicy = lazy(() => import("../pages/Policy"));
export const LazyChangelog = lazy(() => import("../pages/Changelog"));

/* AUTH PAGES */
export const LazySign = lazy(() => import("../pages/auth/SignIn"));
export const LazyForgotPW = lazy(() => import("../pages/auth/ForgotPw"));
export const LazyResetPW = lazy(() => import("../pages/auth/PasswordReset"));
export const LazyRegister = lazy(() => import("../pages/auth/UserRegister"));

/* LAB PAGES */
export const LazyLab = lazy(() => import("../pages/labs/Lab"));
export const LazyLabItem = lazy(() => import("../pages/labs/LabItem"));
export const LazyLabDCI = lazy(() => import("../pages/labs/LabDciAlerts"));

/* INSIDE PAGES */
export const LazyDashboard = lazy(() => import("../pages/inside/Dashboard"));
export const LazyClinicalChecker = lazy(() => import("../pages/inside/APrescriptions"));
export const LazyATC = lazy(() => import("../pages/inside/ATC"));
export const LazyCalculator = lazy(() => import("../pages/inside/Calculator"));
export const LazyDCI = lazy(() => import("../pages/inside/Dci"));
export const LazyDciAlert = lazy(() => import("../pages/inside/DciAlerts"));
export const LazyDetails = lazy(() => import("../pages/inside/Details"));
export const LazyNutritionCenter = lazy(() => import("../pages/inside/Ebsco"));
export const LazyGuidelines = lazy(() => import("../pages/inside/Guidelines"));
export const LazyHealthCalculators = lazy(() => import("../pages/inside/HCalculator"));
export const LazyIV = lazy(() => import("../pages/inside/IVCompability"));
export const LazyIVResults = lazy(() => import("../pages/inside/IVResults"));
export const LazyPInj = lazy(() => import("../pages/inside/PInjections"));
export const LazyPInjResults = lazy(() => import("../pages/inside/PinjDetails"));
export const LazyProducts = lazy(() => import("../pages/inside/Products"));
export const LazyProfile = lazy(() => import("../pages/inside/Profile"));
export const LazySearch = lazy(() => import("../pages/inside/Search"));

/* ADMIN PAGES */
// ADS
export const LazyAdminAdsCrd = lazy(() => import("../pages/inside/admin/ads/CrudAds"));
export const LazyAdminAdsLst = lazy(() => import("../pages/inside/admin/ads/ListAds"));
// AGENTS
export const LazyAdminAgentLst = lazy(() => import("../pages/inside/admin/agents/ListAgents"));
// ENTITIES
export const LazyAdminEntCrd = lazy(() => import("../pages/inside/admin/entities/CrudOpEntities"));
export const LazyAdminEntLst = lazy(() => import("../pages/inside/admin/entities/ListEntities"));
// NOTIFICATIONS
export const LazyAdminNotCrd = lazy(() => import("../pages/inside/admin/notifications/AddNotifications"));
export const LazyAdminNotLst = lazy(() => import("../pages/inside/admin/notifications/ListNotifications"));
// USERS
export const LazyAdminUsersCrd = lazy(() => import("../pages/inside/admin/users/CrudOpUser"));
export const LazyAdminUsersLst = lazy(() => import("../pages/inside/admin/users/ListUsers"));