import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { m } from "framer-motion";

const Loader = () => {
  return (
    <m.div
      style={{ 
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flex: 1,
        minHeight: "100%"
       }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <CircularProgress disableShrink color="secondary" />
    </m.div>
  );
}

export default Loader;
