import React from "react";
import { AppState } from "./AppState";

const AppContext = React.createContext<AppState>({
  isAuthenticated: false,
  user: null,
  containerData: null,
  loading: true,
  setUser: () => { },
  theme: null,
  setTheme: () => { },
  useFunctions: null,
  setAnalyserFn: () => { },
});

export default AppContext;
