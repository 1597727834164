import React, { Suspense } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import {
    authLayoutRoutes,
    withSidebarLayoutRoutes,
    WSBLNotOnSidebarRoutes,
    adminRoutes,
} from "./index";
import { AnimatePresence } from "framer-motion";
import AuthLayout from "../layouts/Auth";
import WidthSidebarLayout from "../layouts/WithSidebar";
import TokenLayout from "../layouts/TokenLayout";
import TokenGuard from "../components/TokenGuard";
import {
    CHANGELOG_PATH,
    DCI_DRUGS_INTERACTIONS,
    DCI_LAB_INTERACTIONS,
    LAB_ITEM_PAGE_PATH,
    LAB_PAGE_PATH,
    LANDING_PATH,
    PRIVACY_PATH,
    SIGN_IN_PATH,
} from "../constants/routes";
import Loader from "../components/Loader";
import {
    LazyChangelog,
    LazyDCI,
    LazyDciAlert,
    LazyLab,
    LazyLabItem,
    LazyLanding,
    LazyPolicy,
} from "./LazyRoutes";

const childRoutes = (Layout: any, routes: any) => {
    return routes.map(
        (
            {
                component: Component,
                guard,
                children,
                path,
                featureCodes,
            }: {
                component: any;
                guard: any;
                children: any;
                path: any;
                featureCodes: any;
            },
            index: number
        ) => {
            const Guard = guard || React.Fragment;
            const AuthGuard = ({ children }: { children: any }) => {
                return Guard === React.Fragment ? (
                    <Guard>{children}</Guard>
                ) : (
                    <Guard featureCodes={featureCodes}>{children}</Guard>
                );
            };

            return children ? (
                children.map((element: any, index: number) => {
                    const Guard = element.guard || React.Fragment;
                    const AuthGuard = ({ children }: { children: any }) => {
                        return Guard === React.Fragment ? (
                            <Guard>{children}</Guard>
                        ) : (
                            <Guard featureCodes={featureCodes}>
                                {children}
                            </Guard>
                        );
                    };
                    return (
                        <Route
                            key={index}
                            path={element.path}
                            exact
                            render={(props) => (
                                <AuthGuard
                                    children={
                                        <Layout>
                                            <element.component {...props} />
                                        </Layout>
                                    }
                                />
                            )}
                        />
                    );
                })
            ) : Component ? (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={(props) => (
                        <AuthGuard
                            children={
                                <Layout>
                                    <Component {...props} />
                                </Layout>
                            }
                        />
                    )}
                />
            ) : null;
        }
    );
};

const Routes = () => {
    return (
        <Router>
            <Suspense fallback={<Loader />}>
                <Route
                    render={({ location }) => (
                        <AnimatePresence initial={false}>
                            <Switch location={location} key={location.pathname}>
                                {childRoutes(AuthLayout, authLayoutRoutes)}
                                {childRoutes(
                                    WidthSidebarLayout,
                                    withSidebarLayoutRoutes
                                )}
                                {childRoutes(WidthSidebarLayout, adminRoutes)}
                                {childRoutes(
                                    WidthSidebarLayout,
                                    WSBLNotOnSidebarRoutes
                                )}
                                <Route
                                    exact
                                    path={LAB_PAGE_PATH}
                                    render={(props) => (
                                        <TokenGuard
                                            children={
                                                <TokenLayout noSPLogo={true}>
                                                    <LazyLab />
                                                </TokenLayout>
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path={LAB_ITEM_PAGE_PATH}
                                    render={(props) => (
                                        <TokenGuard
                                            children={
                                                <TokenLayout>
                                                    <LazyLabItem {...props} />
                                                </TokenLayout>
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    path={DCI_LAB_INTERACTIONS}
                                    render={(props) => (
                                        <TokenGuard
                                            children={
                                                <TokenLayout>
                                                    <LazyDciAlert {...props} />
                                                </TokenLayout>
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={DCI_DRUGS_INTERACTIONS}
                                    render={(props) => (
                                        <TokenGuard
                                            children={
                                                <TokenLayout>
                                                    <LazyDCI />
                                                </TokenLayout>
                                            }
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path={PRIVACY_PATH}
                                    render={(props) => (
                                        <AuthLayout>
                                            <LazyPolicy />
                                        </AuthLayout>
                                    )}
                                />
                                <Route
                                    exact
                                    path={CHANGELOG_PATH}
                                    render={(props) => (
                                        <AuthLayout>
                                            <LazyChangelog />
                                        </AuthLayout>
                                    )}
                                />
                                <Route
                                    exact
                                    path={LANDING_PATH}
                                    render={(props) => <LazyLanding />}
                                />
                                <Redirect to={SIGN_IN_PATH} />
                            </Switch>
                        </AnimatePresence>
                    )}
                />
            </Suspense>
        </Router>
    );
};

export default Routes;
