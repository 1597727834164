import React from "react";
import { SvgIcon } from "@mui/material";

const AtcIcon = (props: any) => {

    return (
        <SvgIcon {...props} viewBox="0 0 173.1 106.2">
            <path d="M12,98.1c5.4,4.3,12.2,6.7,19.4,6.7c2.4,0,4.8-0.3,7.2-0.8l45.1-10.5c0.5-0.1,1-0.4,1.2-0.9s0.4-1,0.2-1.5
            L71.8,33.7c-0.1-0.5-0.4-1-0.9-1.2c-0.4-0.3-1-0.4-1.5-0.2L24.3,42.7c-8.5,2-15.3,7.2-19.6,14v0C1.7,61.6,0,67.4,0,73.3
            c0,2.4,0.3,4.8,0.8,7.2C2.5,87.8,6.6,93.9,12,98.1z"/>
            <path d="M148.8,44.1l-45.1-10.5c-1.1-0.3-2.1,0.4-2.4,1.5L87.9,92.5c-0.3,1.1,0.4,2.1,1.5,2.4l45.1,10.5
            c2.4,0.6,4.8,0.8,7.2,0.8c7.2,0,14-2.4,19.4-6.7c5.4-4.3,9.5-10.3,11.2-17.6c0.6-2.4,0.8-4.8,0.8-7.2c0-6-1.7-11.7-4.7-16.6l0,0
            C164.1,51.3,157.3,46.1,148.8,44.1z"/>
            <path d="M95,33.1c2.4,0,4.7-1,6.3-2.6c1.6-1.6,2.6-3.8,2.6-6.3c0-2.4-1-4.7-2.6-6.3c-1.6-1.6-3.8-2.6-6.3-2.6
            c-2.4,0-4.7,1-6.2,2.6c-1.6,1.6-2.6,3.8-2.6,6.3c0,2.4,1,4.7,2.6,6.3C90.3,32.1,92.5,33.1,95,33.1z"/>
            <path d="M120.2,15.1c1.6-1.6,2.6-3.8,2.6-6.3c0-2.4-1-4.7-2.6-6.3C118.6,1,116.4,0,114,0c-2.4,0-4.7,1-6.2,2.6
            c-1.6,1.6-2.6,3.8-2.6,6.3c0,2.4,1,4.7,2.6,6.3c1.6,1.6,3.8,2.6,6.2,2.6C116.4,17.7,118.6,16.7,120.2,15.1z"/>
            <path d="M78.1,45.7c0,2.3,0.9,4.4,2.5,5.9c1.5,1.5,3.6,2.5,5.9,2.5c2.3,0,4.4-0.9,5.9-2.5c1.5-1.5,2.5-3.6,2.5-5.9
            c0-2.3-0.9-4.4-2.5-5.9c-1.5-1.5-3.6-2.5-5.9-2.5c-2.3,0-4.4,0.9-5.9,2.5C79,41.3,78.1,43.4,78.1,45.7z"/>
            <path d="M59.1,5.8c-1.6,1.6-2.6,3.8-2.6,6.3c0,2.4,1,4.6,2.6,6.2c1.6,1.6,3.8,2.6,6.2,2.6c2.4,0,4.7-1,6.2-2.6
            c1.6-1.6,2.6-3.8,2.6-6.2c0-2.4-1-4.7-2.6-6.3c-1.6-1.6-3.8-2.6-6.2-2.6C62.9,3.2,60.7,4.2,59.1,5.8z"/>
        </SvgIcon>
    );

}

export default AtcIcon;