import React from "react";
import styled from "@emotion/styled/macro";
import {
    Theme,
    Typography,
    useTheme,
    Box
} from "@mui/material";
import { convertToRGA } from "../utils/utils";

const Group = styled.div`
    border-radius:  4px;
    border: 1px solid ${({ theme }: { theme: Theme }) => theme.palette.text.disabled};
    position: relative;
    margin-top: ${({ theme }: { theme: Theme }) => theme.spacing(3)};
    overflow: hidden;
    & > span {
        display: block;
        background: ${({ theme }: { theme: Theme }) => theme.palette.secondary.main};
        color: ${({ theme }: { theme: Theme }) => theme.palette.secondary.contrastText};
        font-size: 12px;
        padding: 5px 10px;
    }
    & > div {
        padding: ${({ theme }: { theme: Theme }) => theme.spacing(6)};
    }
    .no-results {
        padding: 0px !important;
    }
`;

const FlexRows = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  background: ${({ theme }: { theme: Theme }) => theme.palette.secondary.main};
  padding: 5px 10px !important;
  & > span {
    display: block;
    color: ${({ theme }: { theme: Theme }) => theme.palette.secondary.contrastText};
    font-size: 12px;
}
`;

const GroupBox = (props: any) => {

    const theme = useTheme();
    const { title, children, titleBg, noPadRight, icon, ...styleProps } = props;

    return (
        <Group theme={theme} {...styleProps}>
            {
                icon ?
                    <FlexRows theme={theme}>
                        {icon}
                        <Typography
                            variant="caption"
                        >
                            {title}
                        </Typography>
                    </FlexRows> :
                    <Typography
                        variant="caption"
                        sx={{ background: titleBg ? titleBg : "" }}>
                        {title}
                    </Typography>
            }
            <Box
                className={props.noResults ? "no-results" : undefined}
                sx={{ 
                    paddingRight: noPadRight ? noPadRight : "",
                    background: `linear-gradient(
                        to bottom,
                        ${convertToRGA(theme.palette.background.paper, 0.25)},
                        ${convertToRGA(
                            theme.palette.background.paper,
                            0.65
                        )} 10%
                      )`,
                }}
            >
                {children}
            </Box>
        </Group>
    );
};

export default GroupBox;