import React from "react";
import { SvgIcon } from "@mui/material";

const PInjIcon = (props: any) => {

  return (
    <SvgIcon {...props} viewBox="0 0 173.1 173.1">
      <path d="M172.5,32.5L140.6,0.6c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l9.3,9.3l-22.5,22.5l-7.5-7.5h0l-8.3-8.3
            c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8l6.9,6.9L50,91.9l0,0h0l-23,23c-0.3,0.3-0.5,0.7-0.5,1c0,0-0.9,4.8-1.8,11.3
            c-0.9,6.2-1.8,13.9-2,20.4l-22,22c-0.8,0.8-0.8,2,0,2.8c0.8,0.8,2,0.8,2.8,0l22-22c6-0.3,13.6-1.2,19.9-2c6.6-0.9,11.7-1.8,11.7-1.8
            c0.4-0.1,0.8-0.3,1.1-0.6L144,60.3l6.9,6.9c0.8,0.8,2,0.8,2.8,0c0.8-0.8,0.8-2,0-2.8l-8.3-8.3l-7.5-7.5L160.5,26l9.3,9.3
            c0.8,0.8,2,0.8,2.8,0C173.3,34.5,173.3,33.2,172.5,32.5z M55.8,142.9c-1.7,0.3-6.5,1-12.2,1.8c-5.5,0.7-11.8,1.5-17,1.8
            c0.2-5.9,1.1-12.9,1.9-18.6c0.5-3.2,0.9-6,1.2-8c0.2-1,0.3-1.8,0.4-2.3c0-0.1,0-0.2,0-0.2l21.2-21.2L77,121.7L55.8,142.9z
            M87.8,107.8c-0.8,0.8-2,0.8-2.8,0l-6.7-6.7c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l6.7,6.7C88.6,105.7,88.6,107,87.8,107.8z
            M97.1,98.5c-0.8,0.8-2,0.8-2.8,0l-6.7-6.7c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l6.7,6.7C97.8,96.5,97.8,97.8,97.1,98.5z
            M105.2,90.4c-0.8,0.8-2,0.8-2.8,0l-6.7-6.7c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l6.7,6.7C105.9,88.4,105.9,89.7,105.2,90.4z
            M113.7,81.9c-0.8,0.8-2,0.8-2.8,0l-6.7-6.7c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l6.7,6.7C114.5,79.8,114.5,81.1,113.7,81.9z
            M121.8,73.8c-0.8,0.8-2,0.8-2.8,0l-6.7-6.7c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0l6.7,6.7C122.6,71.7,122.6,73,121.8,73.8z"/>
    </SvgIcon>
  );

}

export default PInjIcon;